import React from "react";
import { useState, useEffect } from "react";
import "../EditListItemNamePopup/EditListItemNamePopup.scss";

export const EditListItemNamePopup = (props) => {
  const [listItemName, setListItemName] = useState(props.listItemName);
  const userToken = localStorage.getItem("userToken");

  const updateListItem = async () => {
    console.log("vidis li funkciju");
    try {
      const url =
        "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/list-items-update_w.php";

      const requestData = {
        list_item_id: props.listItemId,
        item_name: listItemName,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Token: userToken,
        },
        body: JSON.stringify(requestData),
      });

      const responseData = await response.json();

      if (response.ok) {
        if (responseData.response) {
          console.log("Ime stavke liste uspešno izmenjeno.");
        } else {
          console.error("Greška:", responseData.message);
        }
        props.setIsListItemNameChanged(true);
        props.onClose();
      } else {
        console.error("Došlo je do greške:", response.status);
      }
    } catch (error) {
      console.error("Došlo je do greške:", error);
    }
  };

  return (
    <div className="edit-list-item-name">
      <div className="list-item-name">
        <div>List item name</div>
        <input
          value={listItemName}
          onChange={(e) => setListItemName(e.target.value)}
        />
      </div>
      <div className="buttons">
        <button onClick={updateListItem}>Save</button>
        <button onClick={props.onClose}>Close</button>
      </div>
    </div>
  );
};
