import React from "react";
import "../Lists/Lists.scss";
import { useState, useEffect } from "react";
import { FiTrash2 } from "react-icons/fi";
import { MdEdit } from "react-icons/md";
import { DeleteListPopup } from "../DeleteListPopup/DeleteListPopup";
import { EditListPopup } from "../EditListPopup/EditListPopup";
import { CreateListPopup } from "../CreateListPopup/CreateListPopup";

export const Lists = () => {
  const [listData, setListData] = useState([]);
  const userToken = localStorage.getItem("userToken");
  const [listIdToDelete, setListIdToDelete] = useState(null);
  const [isListDelete, setIsListDelete] = useState(false);
  const [showDeleteListPopup, setShowDeleteListPopup] = useState(false);
  const [showListPopup, setShowListPopup] = useState(false);
  const [listItemId, setListItemId] = useState("");
  const [listItemName, setListItemName] = useState("");
  const [showCreateListPopup, setShowCreateListPopup] = useState(false);
  const [isListCreated, setIsListCreated] = useState(false);
  const [isListNameUpdated, setIsListNameUpdated] = useState(false);

  useEffect(() => {
    const fetchListData = async () => {
      try {
        const url = `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/lists-select_w.php`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Token: userToken,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        if (data.response) {
          setListData(data.data);
          console.log("list data", listData);
        } else {
          console.error("Error:", data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchListData();
    if (isListDelete) {
      fetchListData();
      setIsListDelete(false);
    }

    if (isListCreated) {
      fetchListData();
      setIsListCreated(false);
    }

    if (isListNameUpdated) {
      fetchListData();
      setIsListNameUpdated(false);
    }
  }, [userToken, isListDelete, isListCreated, isListNameUpdated]);

  const handleDeleteConfirmation = (ticketId) => {
    setListIdToDelete(ticketId);
    setShowDeleteListPopup(true);
  };
  const deleteList = async () => {
    try {
      if (listIdToDelete !== null) {
        const response = await fetch(
          "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/list-delete_w.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Token: userToken,
            },
            body: JSON.stringify({
              list_id: listIdToDelete,
            }),
          }
        );

        const data = await response.json();

        if (response.ok) {
          console.log("Ticket deleted successfully");
          console.log(data);
        } else {
          console.error("Failed to delete ticket:", data.message);
        }
      }
    } catch (error) {
      console.error("Error deleting ticket:", error.message);
    } finally {
      setListIdToDelete(null);
      setShowDeleteListPopup(false);
      setIsListDelete(true);
    }
  };
  return (
    <div className="lists">
      <h2>Listen</h2>

      <div className="lists-table">
        <table>
          <thead>
            <tr>
              <th> Titel</th>
              <th>Autor</th>
              <th>Erstellt am</th>
              <th>Listen Inhalte</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {listData.length === 0 ? (
              <tr>
                <td colSpan="9">No lists available</td>
              </tr>
            ) : (
              listData.map((list) => (
                <tr
                  key={list.list_id}
                  onClick={() => {
                    setShowListPopup(true);
                    setListItemId(list.list_id);
                    setListItemName(list.list_name);
                  }}
                >
                  <td>{list.list_name}</td>
                  <td>2</td>
                  <td>3</td>
                  <td>4</td>
                  <td>
                    <MdEdit
                      onClick={() => {
                        setShowListPopup(true);
                        setListItemId(list.list_id);
                        setListItemName(list.list_name);
                      }}
                    />
                  </td>
                  <td>
                    <FiTrash2
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteConfirmation(list.list_id);
                      }}
                    />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <button onClick={() => setShowCreateListPopup(true)}>
        Create new list
      </button>
      {showDeleteListPopup && (
        <DeleteListPopup
          show={showDeleteListPopup}
          onHide={() => setShowDeleteListPopup(false)}
          onDelete={deleteList}
        />
      )}
      {showListPopup && (
        <EditListPopup
          listItemId={listItemId}
          listItemName={listItemName}
          setIsListNameUpdated={setIsListNameUpdated}
          onClose={() => setShowListPopup(false)}
        />
      )}
      {showCreateListPopup && (
        <CreateListPopup
          setIsListCreated={setIsListCreated}
          onClose={() => setShowCreateListPopup(false)}
        />
      )}
    </div>
  );
};
