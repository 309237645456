import React from "react";
import "../CreateProject/CreateProject.scss";
import { Link, Route, Routes } from "react-router-dom";
import { CreateDetails } from "../CreateDetails/CreateDetails";
import { CreateTicket } from "../CreateTicket/CreateTicket";
import { useState, useEffect, useRef } from "react";
import { AddUsers } from "../AddUsers/AddUsers";
import { Forms } from "../Forms/Forms";
import { SaveDetailsPopup } from "../SaveDetailsPopup/SaveDetailsPopup";
import { BlueprintsAndLevels } from "../BlueprintsAndLevels/BlueprintsAndLevels";

export const CreateProject = () => {
  const [selectedTab, setSelectedTab] = useState("details");
  const [detailsSaved, setDetailsSaved] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const projectID = urlParams.get("project_id");

  console.log("je l vidi projectId", projectID);

  const handleDetailsSaved = () => {
    setDetailsSaved(true);
  };

  const handleTabChange = (tab) => {
    if (!detailsSaved && !projectID) {
      console.log("je l udjes ovde");
      setShowPopup(true);
    } else {
      setSelectedTab(tab);
    }
  };

  return (
    <div className="create-project">
      <nav>
        <ul>
          <li>
            <a
              href="javascript:void(0)"
              className={selectedTab === "details" ? "active" : ""}
              onClick={() => handleTabChange("details")}
            >
              Details
            </a>
          </li>
          <li>
            <a
              href="#addUsers"
              className={
                selectedTab === "add-users" || !detailsSaved ? "disabled" : ""
              }
              onClick={() => handleTabChange("add-users")}
            >
              Add users
            </a>
          </li>
          <li>
            <a
              href="#forms"
              className={
                selectedTab === "forms" || !detailsSaved ? "disabled" : ""
              }
              onClick={() => handleTabChange("forms")}
            >
              Forms
            </a>
          </li>
          <li>
            <a
              href="#ticket"
              className={
                selectedTab === "ticket" || !detailsSaved ? "disabled" : ""
              }
              onClick={() => handleTabChange("ticket")}
            >
              Blueprints and levels
            </a>
          </li>
          <li>
            <a
              href="#create-ticket"
              className={
                selectedTab === "create-ticket" || !detailsSaved
                  ? "disabled"
                  : ""
              }
              onClick={() => handleTabChange("create-ticket")}
            >
              Ticket
            </a>
          </li>
        </ul>
      </nav>

      <div>
        {selectedTab === "details" && (
          <CreateDetails onDetailsSaved={handleDetailsSaved} />
        )}
        {selectedTab === "add-users" && <AddUsers />}
        {selectedTab === "forms" && <Forms />}
        {selectedTab === "ticket" && <BlueprintsAndLevels />}
        {selectedTab === "create-ticket" && <CreateTicket />}
      </div>

      {showPopup && (
        <SaveDetailsPopup show={showPopup} onHide={() => setShowPopup(false)} />
      )}
    </div>
  );
};
