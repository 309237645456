import React from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const AuthCheck = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const userToken = localStorage.getItem("userToken");

    if (!userToken) {
      navigate("/");
    }
  }, [navigate]);

  return null;
};

export default AuthCheck;
