import React, { useState, useEffect, useRef } from "react";
import { CiPaperplane } from "react-icons/ci";
import { TiPlus } from "react-icons/ti";
import { CiEdit } from "react-icons/ci";
import { MdOutlineDelete } from "react-icons/md";
import "../BlueprintsAndLevels/BlueprintsAndLevels.scss";
import { AddLevelPopup } from "../AddLevelPopup/AddLevelPopup";
import { DeleteLevelPopup } from "../DeleteLevelPopup/DeleteLevelPopup";

const PDFJS = window.pdfjsLib;

export const BlueprintsAndLevels = () => {
  const [layers, setLayers] = useState([]);
  const [selectedLayer, setSelectedLayer] = useState(null);
  const [selectedLayerData, setSelectedLayerData] = useState(null);
  const userToken = localStorage.getItem("userToken");
  const [modalShow, setModalShow] = useState(false);
  const [layersChanged, setLayersChanged] = useState(false);
  const [editParameter, setEditParameter] = useState("");
  const fileInputRef = useRef(null);
  const urlParams = new URLSearchParams(window.location.search);
  const projectID = urlParams.get("project_id");
  const [pdf, setPdf] = useState("");
  const [width, setWidth] = useState(0);
  const [images, setImages] = useState([]);
  const [height, setHeight] = useState(0);
  const [pdfRendering, setPdfRendering] = useState("");
  const [pageRendering, setPageRendering] = useState("");
  const [showDeleteModal, setDeleteModalShow] = useState(false);
  const [selectedImage, setSelectedImgae] = useState("");
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showText, setShowText] = useState(false);

  useEffect(() => {
    if (images.length === 0) {
      setImageLoaded(false);
    }
  }, [images]);

  useEffect(() => {
    let timer;
    if (showText) {
      timer = setTimeout(() => {
        setShowText(false);
      }, 5000);
    }

    return () => clearTimeout(timer);
  }, [showText]);

  useEffect(() => {
    if (!projectID) {
      return;
    }
    if (projectID) {
      fetch(
        `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-select_w.php?project_id=${projectID}`,
        {
          method: "GET",
          headers: {
            Token: userToken,
          },
        }
      )
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error("Server je vratio status: " + response.status);
          }
        })
        .then((data) => {
          if (data.response) {
            const projectData = data.data;
            document.title = `Ciric documentation - ${projectData.project_name}`;
          } else {
            console.error(data.message);
          }
        })
        .catch((error) => {
          console.error("Greška prilikom dohvatanja podataka:", error);
        });
    } else {
      document.title = `Ciric documentation`;
    }
  }, [projectID]);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-layers-select_w.php?project_id=${projectID}`,
        {
          method: "GET",
          headers: {
            Token: userToken,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Not valid");
      }

      const data = await response.json();
      setLayers(data.data);
      setLayersChanged(false);
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  const handleLayerAdded = () => {
    setLayersChanged(true);
  };

  useEffect(() => {
    // if (!layersChanged) {
    //   return;
    // }
    fetchData();
  }, [layersChanged]);

  const fetchLayerImage = async (layerId) => {
    try {
      const response = await fetch(
        `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-layer-image-select_w.php?project_layer_id=${layerId}`,
        {
          method: "GET",
          headers: {
            Token: userToken,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Not valid");
      }

      const data = await response.json();
      const imageUrl = data.data.img_url;
      setSelectedImgae(imageUrl);
      return imageUrl;
    } catch (error) {
      console.error("Error fetching layer image:", error.message);
      return null;
    }
  };

  const handleLayerClick = async (layerId) => {
    setSelectedLayer((prevSelected) =>
      prevSelected === layerId ? null : layerId
    );

    const selectedLayerData = layers.find(
      (layer) => layer.project_layer_id === layerId
    );
    setSelectedLayerData(selectedLayerData);

    if (selectedLayerData) {
      try {
        const image = await fetchLayerImage(layerId);
        setImages(image ? [image] : []);
        console.log("sta je slika", images);
      } catch (error) {
        console.error("Error handling layer click:", error.message);
      }
    }
  };

  const handleAddLevelClick = () => {
    setModalShow(true);
    setSelectedLayer(null);
    setSelectedLayerData(null);
    setEditParameter(0);
  };

  const handleEditClick = () => {
    setModalShow(true);
    setEditParameter(1);
  };

  const handleAddPdfClick = () => {
    console.log("handleAddPdfClick called");
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    try {
      setPdfRendering(true);
      const file = event.target.files[0];
      const uri = URL.createObjectURL(file);
      var _PDF_DOC = await PDFJS.getDocument(uri).promise;
      console.log("pdf doc", _PDF_DOC);
      setPdf(_PDF_DOC);
      setPdfRendering(false);
      document.getElementById("file-to-upload").value = "";
    } catch (error) {
      alert(error.message);
    }
  };

  async function renderPage() {
    setPageRendering(true);
    const imagesList = [];
    const canvas = document.createElement("canvas");

    console.log("pdf", pdf);

    var page = await pdf.getPage(1);
    var viewport = page.getViewport({ scale: 2 });
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    var render_context = {
      canvasContext: canvas.getContext("2d"),
      viewport: viewport,
    };
    setWidth(viewport.width);
    setHeight(viewport.height);
    await page.render(render_context).promise;
    let img = canvas.toDataURL("image/png", 1.0);
    imagesList.push(img);

    setImages(imagesList);
    console.log("slika je", images);
    setPageRendering(false);
    setShowText(true);

    try {
      let requestBody = {
        project_layer_id: selectedLayer,
        img_base64: img,
      };

      const response = await fetch(
        `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-layer-image-upload_w.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Token: userToken,
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error("Not valid");
      }

      const data = await response.json();
      console.log("Successfully added/updated layer with ID:", data.data);
    } catch (error) {
      console.error("Error saving layer:", error.message);
    }
  }

  useEffect(() => {
    if (!pdf) {
      return;
    }
    pdf && renderPage();
    // eslint-disable-next-line
  }, [pdf]);

  const handleDeleteLayerClick = async (layerId) => {
    try {
      const response = await fetch(
        "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-layer-delete_w.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Token: userToken,
          },
          body: JSON.stringify({
            project_layer_id: layerId,
          }),
        }
      );

      if (!response.ok) {
        const data = await response.json();
        throw new Error(data.message || "Not valid");
      }

      setLayers((prevLayers) =>
        prevLayers.filter((layer) => layer.project_layer_id !== layerId)
      );
    } catch (error) {
      console.error("Error deleting layer:", error.message);
    }
  };

  return (
    <div className="blueprints-and-levels">
      <button onClick={handleAddLevelClick}>Add level</button>
      <div className="select-level">
        {layers.length > 0 && (
          <div id="layers" className="layers">
            {layers.map((layer) => (
              <div
                key={layer.project_layer_id}
                id={layer.project_layer_id}
                className={`layer${findParentCount(layer, layers)} ${
                  selectedLayer === layer.project_layer_id ? "selected" : ""
                }`}
                onClick={() => handleLayerClick(layer.project_layer_id)}
              >
                <CiPaperplane />
                {layer.layer_name}
                <CiEdit className="edit" onClick={handleEditClick} />
                <MdOutlineDelete
                  className="delete"
                  onClick={() => setDeleteModalShow(true)}
                />
              </div>
            ))}
          </div>
        )}
        <div className="open-plan">
          {selectedLayerData && (
            <>
              <p>Selected Layer: {selectedLayerData.layer_name}</p>
              <div className="dashed-border" onClick={handleAddPdfClick}>
                {images.length === 0 ? (
                  <>
                    <TiPlus />
                    <input
                      type="file"
                      id="file-to-upload"
                      accept=".pdf"
                      onChange={handleFileChange}
                      ref={fileInputRef}
                      style={{ display: "none" }}
                    />
                    <span>Add PDF</span>
                    {pageRendering && !imageLoaded ? (
                      <p>Wait until the image is loaded...</p>
                    ) : null}
                  </>
                ) : (
                  <div id="image-convas-row">
                    {showText && (
                      <p>Wait a few seconds before you get out of here.</p>
                    )}
                    <div>
                      {images.map((image, idx) => (
                        <div key={idx}>
                          <img
                            id="image-generated"
                            src={images[0] || selectedImage}
                            alt="pdfImage"
                            style={{
                              width: "100%",
                              height: "100%",
                              margin: "0",
                              border: "none",
                            }}
                            onLoad={() => setImageLoaded(true)}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      {modalShow && (
        <AddLevelPopup
          show={modalShow}
          onHide={() => {
            setModalShow(false);
            setSelectedLayer(null);
            setEditParameter(null);
          }}
          onLayerAdded={handleLayerAdded}
          selectedLayerData={selectedLayerData}
          edit={editParameter}
        />
      )}
      {showDeleteModal && (
        <DeleteLevelPopup
          show={showDeleteModal}
          onHide={() => setDeleteModalShow(false)}
          onDelete={() => {
            handleDeleteLayerClick(selectedLayer);
            setDeleteModalShow(false);
          }}
        />
      )}
    </div>
  );
};

const findParentCount = (currentLayer, allLayers) => {
  let count = 0;

  while (currentLayer.parent_layer_id !== null) {
    count++;
    for (let i = 0; i < allLayers.length; i++) {
      if (currentLayer.parent_layer_id === allLayers[i].project_layer_id) {
        currentLayer = allLayers[i];
        break;
      }
    }
  }

  return count;
};
