import React from "react";
import "../ChooseRolePopup/ChooseRolePopup.scss";
import { IoCloseOutline } from "react-icons/io5";
import { useState, useEffect } from "react";

export const ChoseRolePopup = (props) => {
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState("");
  const userToken = localStorage.getItem("userToken");
  const urlParams = new URLSearchParams(window.location.search);
  const projectID = urlParams.get("project_id");

  let selectedUserIds = JSON.parse(sessionStorage.getItem("selectedUsers"));

  console.log(" u role popup usr ids", selectedUserIds);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await fetch(
          "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-roles_w.php",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Token: userToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Request failed");
        }

        const data = await response.json();
        const filteredRoles = data.data.filter(
          (role) => role.role !== " " && role.role !== null
        );
        console.log("filteredRole", filteredRoles);
        setRoles(filteredRoles);
        console.log("uloge role", roles);
      } catch (error) {
        console.error("Error fetching roles:", error.message);
      }
    };

    fetchRoles();
  }, []);

  const handleRadioChange = (selectedRoleId) => {
    setSelectedRoles(selectedRoleId);
    console.log("selected role", selectedRoleId);
  };

  const handleFinishClick = async () => {
    try {
      if (!selectedUserIds || selectedUserIds.length === 0) {
        console.error("No users selected");
        return;
      }

      const projectRoleID = selectedRoles ? selectedRoles : null;

      console.log("project roleId", projectRoleID);

      console.log("project roleId pre slanja", projectRoleID);
      console.log("sel user id", selectedUserIds);

      const response = await fetch(
        "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-users-add_w.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Token: userToken,
          },
          body: JSON.stringify({
            project_id: projectID,
            users: selectedUserIds,
            project_role_id: projectRoleID,
          }),
        }
      );

      console.log("project roleId posle slanja", projectRoleID);

      if (response.status === 200) {
        const data = await response.json();
        console.log("Data after adding user to project:", data);

        if (data.response) {
          props.setUserAdded(true);
          console.log("User added successfully.");
          props.onClose();
        } else {
          console.error(
            "Unexpected response data:",
            data.message || "Unknown error"
          );
        }
      } else if (response.status === 400) {
        const data = await response.json();
        console.error("Bad request:", data.message || "Unknown error");
      } else {
        console.error(`Unexpected status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error adding user to project:", error.message);
    }
  };

  return (
    <div className="chose-role-popup">
      <IoCloseOutline className="close-icon" onClick={props.onClose} />
      <h2>Choose a role:</h2>
      {roles.map((role) => (
        <div className="item" key={role.role_id}>
          <input
            type="radio"
            id={`roleRadio-${role.role_id}`}
            name="selectedRole"
            value={role.role_id}
            checked={selectedRoles === role.role_id}
            onChange={() => handleRadioChange(role.role_id)}
          />
          <label htmlFor={`roleRadio-${role.role_id}`}>{role.role}</label>
        </div>
      ))}

      <h4>Subcontractors and watchers will not take this role!</h4>
      <button onClick={handleFinishClick}>Finish</button>
    </div>
  );
};
