import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export const FillInAllFieldsPopup = (props) => {
  console.log("sta je props message", props.popupMessage);
  return (
    <Modal show={props.show} onHide={props.onHide} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Alle Felder im Ticket sind Pflichtfelder</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.popupMessage}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Schließen
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
