import React from "react";
import "../CreateTicket/CreateTicket.scss";
import { FaPlus } from "react-icons/fa";
import { useState, useEffect } from "react";
import { CreateTicketPopup } from "../CreateTicketPopup/CreateTicketPopup";
import { FiTrash2 } from "react-icons/fi";
import { DeleteTicketPopup } from "../DeleteTicketPopup/DeleteTicketPopup";
import LeafletMap from "../LeafetMap/LeafetMap";
import LeafletMapCoordinates from "../LeafletMapCoordinates/LeafletMapCoordinates";
import { useNavigate } from "react-router-dom";
import { ExportToExcel } from "../ExportToExcel/ExportToExcel";

export const CreateTicket = () => {
  const userToken = localStorage.getItem("userToken");
  const urlParams = new URLSearchParams(window.location.search);
  const projectID = urlParams.get("project_id");
  const [showModal, setShowModal] = useState(false);
  const [hasTickets, setHasTickets] = useState(false);
  const [tickets, setTickets] = useState([]);
  const [edit, setEdit] = useState(0);
  const [isTicketCreate, setIsTicketCreate] = useState(false);
  const [isTicketUpdate, setIsTicketUpdate] = useState(false);
  const [isTicketDelete, setIsTicketDelete] = useState(false);
  const [isImageDelete, setIsImageDelete] = useState(false);
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [ticketIdToDelete, setTicketIdToDelete] = useState(null);
  const [layers, setLayers] = useState([]);
  const [layer, setLayer] = useState(null);
  const [mapCoordinates, setMapCoordinates] = useState([]);
  const [imageData, setImageData] = useState(null);
  const navigate = useNavigate();
  const [excelData, setExcelData] = useState([]);
  const fileName = "myfile";
  const [projectName, setProjectName] = useState("");

  useEffect(() => {
    if (projectID) {
      fetch(
        `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-select_w.php?project_id=${projectID}`,
        {
          method: "GET",
          headers: {
            Token: userToken,
          },
        }
      )
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error("Server je vratio status: " + response.status);
          }
        })
        .then((data) => {
          if (data.response) {
            const projectData = data.data;
            document.title = `Ciric documentation - ${projectData.project_name}`;
          } else {
            console.error(data.message);
          }
        })
        .catch((error) => {
          console.error("Greška prilikom dohvatanja podataka:", error);
        });
    } else {
      document.title = `Ciric documentation`;
    }
  }, [projectID]);

  useEffect(() => {
    if (projectID) {
      fetch(
        `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-select_w.php?project_id=${projectID}`,
        {
          method: "GET",
          headers: {
            Token: userToken,
          },
        }
      )
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error("Server je vratio status: " + response.status);
          }
        })
        .then((data) => {
          if (data.response) {
            const projectData = data.data;
            document.title = `Ciric documentation - ${projectData.project_name}`;
          } else {
            console.error(data.message);
          }
        })
        .catch((error) => {
          console.error("Greška prilikom dohvatanja podataka:", error);
        });
    } else {
      document.title = `Ciric documentation`;
    }
  }, [projectID]);

  useEffect(() => {
    if (!layer) {
      return;
    }
    const fetchLayerImage = async () => {
      try {
        const response = await fetch(
          `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-layer-image-select_w.php?project_layer_id=${layer}`,
          {
            method: "GET",
            headers: {
              Token: userToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Not valid");
        }

        const data = await response.json();
        setImageData(data.data);
        console.log("image data", imageData);
      } catch (error) {
        console.error("Error fetching layer image:", error.message);
        return null;
      }
    };
    fetchLayerImage();
  }, [layer]);

  useEffect(() => {
    if (!projectID) {
      return;
    }
    const fetchProjectLayers = async () => {
      const endpoint = `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-layers-select_w.php?project_id=${projectID}`;

      try {
        const response = await fetch(endpoint, {
          method: "GET",
          headers: {
            Token: userToken,
          },
        });

        const data = await response.json();

        if (data.response) {
          setLayers(data.data);
          console.log("sta je layers", layers);
        } else {
          console.error("Error fetching project layers:", data.message);
        }
      } catch (error) {
        console.error("Error fetching project layers:", error);
      }
    };

    fetchProjectLayers();
  }, [projectID]);

  useEffect(() => {
    console.log("sta je layers", layers);
  }, [layers]);

  useEffect(() => {
    if (!projectID) {
      return;
    }
    const fetchTickets = async () => {
      console.log("je l udje ovde");
      try {
        const response = await fetch(
          `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/tickets-select_w.php?project_id=${projectID}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Token: userToken,
            },
          }
        );

        const data = await response.json();

        if (data.response && data.data.length > 0) {
          if (layer) {
            const ticketsData = data.data.filter(
              (ticket) => ticket.project_layer_id == layer
            );
            console.log("layer tiketi", ticketsData);
            setTickets(ticketsData);
            const coordinates = ticketsData.map((ticket) => ({
              x: ticket.x,
              y: ticket.y,
            }));

            console.log("Koordinate x i y:", coordinates);
            setMapCoordinates(coordinates);
            console.log("koordinate", mapCoordinates);
          } else {
            setTickets(data.data);
          }

          console.log("lista tiketa", tickets);
        } else {
        }
      } catch (error) {
        console.error("Error fetching tickets:", error.message);
      }
    };

    fetchTickets();

    if (isTicketDelete) {
      fetchTickets();
      setIsTicketDelete(false);
    }

    if (isImageDelete) {
      fetchTickets();
      setIsImageDelete(false);
    }

    if (isTicketCreate) {
      fetchTickets();
      setIsTicketCreate(false);
    }

    if (isTicketUpdate) {
      fetchTickets();
      setIsTicketUpdate(false);
    }
  }, [
    projectID,
    isTicketDelete,
    isTicketCreate,
    isImageDelete,
    layer,
    isTicketUpdate,
  ]);

  const handleRowClick = (ticketId) => {
    console.log("tiket id", ticketId);
    setSelectedTicketId(ticketId);
    setEdit(1);
    setShowModal(true);
  };

  const handleDeleteConfirmation = (ticketId) => {
    setTicketIdToDelete(ticketId);
    setShowDeletePopup(true);
  };
  const deleteTicket = async () => {
    try {
      if (ticketIdToDelete !== null) {
        const response = await fetch(
          "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/ticket-delete_w.php",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Token: userToken,
            },
            body: JSON.stringify({
              ticket_id: ticketIdToDelete,
            }),
          }
        );

        const data = await response.json();

        if (response.ok) {
          console.log("Ticket deleted successfully");
          console.log(data);
        } else {
          console.error("Failed to delete ticket:", data.message);
        }
      }
    } catch (error) {
      console.error("Error deleting ticket:", error.message);
    } finally {
      setTicketIdToDelete(null);
      setShowDeletePopup(false);
      setIsTicketDelete(true);
    }
  };

  const handleCreateLink = () => {
    if (projectID) {
      const projectTicketsURL = `/view-project-tickets/${projectID}`;
      window.open(projectTicketsURL, "_blank");
    } else {
      alert("Molimo odaberite projekat pre nego što kreirate link.");
    }
  };

  const handleExportToPDF = async () => {
    const baseUrl =
      "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/pdf-report.php";
    const urlWithProjectId = `${baseUrl}?project_id=${projectID}&download=true`;

    const a = document.createElement("a");
    a.href = urlWithProjectId;
    a.target = "_blank";
    a.rel = "noopener noreferrer";

    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/tickets-measurements-select_w.php?project_id=${projectID}`,
          {
            headers: {
              Token: userToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const responseData = await response.json();
        setProjectName(responseData.data.project_name);
        setExcelData(responseData.data.tickets);
        console.log("excel", excelData);
      } catch (error) {
        console.log(error.message);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="create-ticket">
      <div className="add-ticket-and-filter">
        <button onClick={() => setShowModal(true)}>
          {" "}
          <FaPlus />
          Create ticket
        </button>

        <select
          id="layer"
          value={layer}
          onChange={(e) => setLayer(e.target.value)}
        >
          <option value="">Filter tickets by layer</option>
          {layers.map((layer) => (
            <option key={layer.project_layer_id} value={layer.project_layer_id}>
              {layer.layer_name}
            </option>
          ))}
        </select>
        <button onClick={handleCreateLink}>Create link</button>
        {excelData && (
          <ExportToExcel apiData={excelData} fileName={projectName} />
        )}
        <button onClick={handleExportToPDF}>Export to PDF</button>
      </div>

      {layer && imageData && mapCoordinates && !showModal && (
        <LeafletMapCoordinates
          imageUrl={imageData.img_url}
          imgHeight={imageData.img_height}
          imgWidth={imageData.img_width}
          pins={mapCoordinates}
          mapHeight="700px"
          mapWidth="1500px"
          enableDrag={false}
          tickets={tickets}
          setIsImageDelete={setIsImageDelete}
          handleRowClick={handleRowClick}
          user={1}
        />
      )}

      <div className="tickets-table">
        <table>
          <thead>
            <tr>
              <th>Order number</th>
              <th>ID</th>
              <th>Zuständiger</th>
              <th> Status</th>
              <th> Empfänger</th>
              <th> Titel</th>
              <th>Autor</th>
              <th>Aktualisiert am</th>
              <th>Aktualisiert von</th>
              <th>Datum</th>
              <th>Brandschutzsystem</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {tickets.length === 0 ? (
              <tr>
                <td colSpan="9">No projects available</td>
              </tr>
            ) : (
              tickets.map((ticket) => (
                <tr
                  key={ticket.project_id}
                  onClick={() => handleRowClick(ticket.ticket_id)}
                >
                  <td>{ticket.order_number}</td>

                  <td>{ticket.ticket_id}</td>
                  <td>{ticket.assignee_name}</td>
                  <td>{ticket.ticket_status}</td>
                  <td></td>
                  <td>{ticket.title}</td>
                  <td>{ticket.author_name}</td>
                  <td>{ticket.created_date}</td>
                  <td>
                    {ticket.updated_name !== null ? ticket.updated_name : ""}
                  </td>
                  <td>{ticket.updated_date}</td>
                  <td>""</td>
                  <td>
                    <FiTrash2
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteConfirmation(ticket.ticket_id);
                      }}
                    />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {showModal && (
        <CreateTicketPopup
          onHide={() => {
            setShowModal(false);
            setSelectedTicketId(null);
            setEdit(0);
          }}
          selectedTicketId={selectedTicketId}
          edit={edit}
          setIsTicketCreate={setIsTicketCreate}
          setIsTicketUpdate={setIsTicketUpdate}
          filteredLayer={layer}
          user={1}
        />
      )}
      {showDeletePopup && (
        <DeleteTicketPopup
          show={showDeletePopup}
          onHide={() => setShowDeletePopup(false)}
          onDelete={deleteTicket}
        />
      )}
    </div>
  );
};
