import React from "react";
import "../EditBrandschutzdokuRichtigListPopup/EditBrandschutzdokuRichtigListPopup.scss";
import { IoCloseOutline } from "react-icons/io5";
import { useState, useEffect } from "react";

export const EditBrandschutzdokuRichtigListPopup = (props) => {
  const [listItems, setListItems] = useState([]);
  const [selectedLists, setSelectedLists] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const userToken = localStorage.getItem("userToken");
  const urlParams = new URLSearchParams(window.location.search);
  const projectID = urlParams.get("project_id");

  console.log("sta je list id", props.listId);

  useEffect(() => {
    const fetchListItems = async () => {
      try {
        const response = await fetch(
          `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/list-items-select_w.php?list_id=${props.listId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Token: userToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setListItems(data.data);
        console.log("data data", data.data);
        console.log("list items", setListItems);
      } catch (error) {
        console.error("Error fetching list items:", error);
      }
    };

    fetchListItems();
  }, [userToken, props.listId]);

  useEffect(() => {
    const fetchSelectedListItems = async () => {
      try {
        const response = await fetch(
          `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-form-list-items-select_w.php?project_id=${projectID}&form_field_id=${props.currentFormFieldId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Token: userToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        if (data.response) {
          const selectedListItems = data.data;
          console.log("Selected list items:", selectedListItems);
          setSelectedLists(selectedListItems.map((item) => item.list_item_id));
          console.log("proradi", selectedLists);
        } else {
          console.error("Error fetching selected list items:", data.message);
        }
      } catch (error) {
        console.error("Error fetching selected list items:", error);
      }
    };

    fetchSelectedListItems();
  }, [projectID, props.currentFormFieldId, userToken]);

  const handleCheckboxChange = (listId) => {
    setSelectedLists((prevSelected) => {
      if (prevSelected.includes(listId)) {
        return prevSelected.filter((id) => id !== listId);
      } else {
        return [...prevSelected, listId];
      }
    });
  };

  const handleCheckAll = () => {
    const allCheckboxIds = listItems.map((list) => list.list_item_id);
    setSelectedLists(allCheckboxIds);
  };

  const handleUncheckAll = () => {
    setSelectedLists([]);
  };

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredList = listItems.filter((item) =>
    item.item_name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  console.log("prpc formfield id", props.currentFormFieldId);
  const handleApplyButtonClick = () => {
    const listMultiselect = selectedLists.length > 0 ? 1 : 0;
    console.log("multiselect", listMultiselect);
    const listItemsArray = listItems.map((list) => ({
      selected: selectedLists.includes(list.list_item_id) ? 1 : 0,
      list_item_id: list.list_item_id,
    }));

    console.log("list item array", listItemsArray);

    const requestBody = {
      project_id: projectID,
      form_field_id: props.currentFormFieldId,
      list_id: props.listId,
      list_multiselect: listMultiselect,
      list_items_array: listItemsArray,
    };

    fetch(
      "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-form-list-items-save_w.php",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Token: userToken,
        },
        body: JSON.stringify(requestBody),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        props.onClose();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="edit-list">
      <IoCloseOutline className="close-icon" onClick={props.onClose} />

      <div className="top-of-page">
        Bearbeiten: Brandschutzdoku Richtig » Brandschutzsystem »
        Brandschutzsystem
      </div>
      <div className="middle-of-page">
        <div className="configure-list">
          <div className="field">Liste Konfigurieren:</div>
          <div className="checkbox-field">
            <input type="checkbox" id="checked" name="checked" />

            <div> Mehrfachauswahl erlauben</div>
          </div>
        </div>
        <div className="choose-class">
          <div className="name">Auswählen:</div>
          <div className="buttons-and-search">
            <button onClick={handleCheckAll}>Alle</button>
            <button onClick={handleUncheckAll}>Keine</button>
            <input
              type="search"
              placeholder="Suchen"
              id="site-search"
              name="search"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </div>
      </div>
      <div className="bottom-of-page">
        <div className="bottom-of-page">
          {filteredList.map((list) => (
            <div className="check-item" key={list.list_item_id}>
              <input
                type="checkbox"
                id={`checkbox-${list.list_item_id}`}
                name={`checkbox-${list.list_item_id}`}
                checked={selectedLists.includes(list.list_item_id)}
                onChange={() => handleCheckboxChange(list.list_item_id)}
              />
              <label htmlFor={`checkbox-${list.list_item_id}`}>
                {list.item_name}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className="buttons">
        <button onClick={handleApplyButtonClick}>Bestätigen</button>
        <button onClick={props.onClose}>Aufgeben</button>
      </div>
    </div>
  );
};
