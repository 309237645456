import { useState, useEffect, useLayoutEffect } from "react";
import { useRef } from "react";
import "../SetPinPopup/SetPinPopup.scss";
import { IoCloseOutline } from "react-icons/io5";
import { CiZoomIn } from "react-icons/ci";
import { CiZoomOut } from "react-icons/ci";
import { TiPlus } from "react-icons/ti";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { useMapContext } from "../LeafetMap/LeafetMap";

import { FaMapMarkerAlt } from "react-icons/fa";
import LeafletMap from "../LeafetMap/LeafetMap";
const PDFJS = window.pdfjsLib;

export const SetPinPopup = (props) => {
  const userToken = localStorage.getItem("userToken");
  const urlParams = new URLSearchParams(window.location.search);
  const projectID = urlParams.get("project_id");
  const [imgUrl, setImgUrl] = useState(null);
  const [imgWidth, setImgWidth] = useState(0);
  const [imgHeight, setImgHeight] = useState(0);
  const projectLayerId = props.layer;
  const savedPinX = props.savedPinX;
  const savedPinY = props.savedPinY;
  const [zoomLevel, setZoomLevel] = useState(0);
  const [startX, setStartX] = useState(null);
  const [startY, setStartY] = useState(null);
  const [imagePosition, setImagePosition] = useState({ x: 0, y: 0 });
  const [pdf, setPdf] = useState("");
  const [width, setWidth] = useState(0);
  const [images, setImages] = useState([]);
  const [height, setHeight] = useState(0);
  const [pdfRendering, setPdfRendering] = useState("");
  const [pageRendering, setPageRendering] = useState("");
  const fileInputRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [movePin, setMovePin] = useState(false);
  const [moveImage, setMoveImage] = useState(false);
  const [activeElement, setActiveElement] = useState(null);
  const [pinPosition, setPinPosition] = useState({ x: 0, y: 20 });
  const imageElements = document.getElementsByClassName("img");
  const [pinMarker, setPinMarker] = useState(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const imageRef = useRef(null);
  const pinRef = useRef(null);
  const { PinX, PinY, PlanImg, imageHeight, imageWidth } = useMapContext();

  console.log("layer id", projectLayerId);
  console.log("saved x,y u set pin", savedPinX, savedPinY);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await fetch(
          `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-layer-image-select_w.php?project_layer_id=${projectLayerId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Token: userToken,
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          if (data.response) {
            setImgUrl(data.data.img_url);
            setImgWidth(data.data.img_width);
            setImgHeight(data.data.img_height);
          } else {
            console.error("Greška u odgovoru:", data.message);
          }
        } else {
          console.error(
            "Greška prilikom dohvata slike:",
            response.status,
            response.statusText
          );
        }
      } catch (error) {
        console.error("Greška prilikom izvršavanja HTTP zahteva:", error);
      }
    };

    fetchImage();
  }, [projectLayerId]);

  const handleAddPdfClick = () => {
    console.log("handleAddPdfClick called");
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    try {
      setPdfRendering(true);
      const file = event.target.files[0];
      const uri = URL.createObjectURL(file);
      console.log("sta je pdf js", PDFJS);
      var _PDF_DOC = await PDFJS.getDocument({ url: uri }).promise;
      console.log("pdf doc", _PDF_DOC);
      setPdf(_PDF_DOC);
      setPdfRendering(false);
      document.getElementById("file-to-upload").value = "";
    } catch (error) {
      alert(error.message);
    }
  };

  async function renderPage() {
    console.log("je l lazi u rener page");
    setPageRendering(true);
    const imagesList = [];
    const canvas = document.createElement("canvas");

    console.log("pdf", pdf);

    console.log("je l udjes");
    var page = await pdf.getPage(1);
    var viewport = page.getViewport({ scale: 2 });
    canvas.height = viewport.height;
    canvas.width = viewport.width;
    var render_context = {
      canvasContext: canvas.getContext("2d"),
      viewport: viewport,
    };
    console.log("page lenght", pdf.numPages);
    setWidth(viewport.width);
    setHeight(viewport.height);
    await page.render(render_context).promise;
    let img = canvas.toDataURL("image/png", 0.9);
    console.log("sta je img", img);
    imagesList.push(img);

    setImages(imagesList);
    setImgUrl(imagesList[0]);
    console.log("slika je", images);
    setPageRendering(false);

    try {
      let requestBody = {
        project_layer_id: projectLayerId,
        img_base64: img,
      };

      const response = await fetch(
        `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-layer-image-upload_w.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Token: userToken,
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        throw new Error("Not valid");
      }

      const data = await response.json();
      console.log("Successfully added/updated layer with ID:", data.data);
    } catch (error) {
      console.error("Error saving layer:", error.message);
    } finally {
      setActiveElement(null);
      setPageRendering(false);
    }
  }

  return (
    <div className="set-pin-popup">
      <div
        className="image"
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
        }}
      >
        {imgUrl ? (
          <LeafletMap
            imageUrl={imgUrl}
            imgWidth={imgWidth}
            imgHeight={imgHeight}
            pinX={PinX || savedPinX || 0}
            pinY={PinY || savedPinY || 0}
            draggable={true}
          />
        ) : (
          <div className="dashed-border" onClick={handleAddPdfClick}>
            <TiPlus />
            <input
              type="file"
              id="file-to-upload"
              accept=".pdf"
              onChange={handleFileChange}
              ref={fileInputRef}
              style={{ display: "none" }}
            />
            <span>Add PDF</span>
            {pdfRendering && "PDF is rendering..."}
          </div>
        )}
      </div>
      <div
        className="icons-container"
        style={{ position: "absolute", top: 0, right: 0, zIndex: 100 }}
      >
        <div className="top-of-page">
          <IoCloseOutline className="close-icon" onClick={props.onHide} />
        </div>
      </div>
    </div>
  );
};
