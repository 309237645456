import React from "react";
import "../EditProject/EditProject.scss";
import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { EditDetails } from "../EditDetails/EditDetails";
import { BsArrow90DegLeft } from "react-icons/bs";
import { EditAddUsers } from "../EditAddUsers/EditAddUsers";
import { Forms } from "../Forms/Forms";
import { BlueprintsAndLevels } from "../BlueprintsAndLevels/BlueprintsAndLevels";
import { useLocation } from "react-router-dom";
import { CreateTicket } from "../CreateTicket/CreateTicket";
import { PrintTicket } from "../PrintTicket/PrintTicket";
import { Print } from "../Print/Print";

export const EditProject = () => {
  const [selectedTab, setSelectedTab] = useState("details");
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const deleteParam = state?.delete;
  console.log("delete param", deleteParam);
  const urlParams = new URLSearchParams(window.location.search);
  const userToken = localStorage.getItem("userToken");

  const projectID = urlParams.get("project_id");
  useEffect(() => {
    if (projectID) {
      fetch(
        `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-select_w.php?project_id=${projectID}`,
        {
          method: "GET",
          headers: {
            Token: userToken,
          },
        }
      )
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error("Server je vratio status: " + response.status);
          }
        })
        .then((data) => {
          if (data.response) {
            const projectData = data.data;
            document.title = `Ciric documentation - ${projectData.project_name}`;
          } else {
            console.error(data.message);
          }
        })
        .catch((error) => {
          console.error("Greška prilikom dohvatanja podataka:", error);
        });
    } else {
      document.title = `Ciric documentation`;
    }
  }, [projectID]);

  const determineRoute = () => {
    if (deleteParam === 1) {
      return "/homePage/deletedProjects";
    } else {
      return "/homePage/listOfProjects";
    }
  };

  return (
    <div className="edit-project">
      <div className="top-of-page">
        <BsArrow90DegLeft onClick={() => navigate(determineRoute())} />
        <nav>
          <ul>
            <li>
              <a
                href="javascript:void(0)"
                className={selectedTab === "details" ? "active" : ""}
                onClick={() => setSelectedTab("details")}
              >
                Details
              </a>
            </li>
            <li>
              <a
                href="#addUsers"
                className={selectedTab === "add-users" ? "active" : ""}
                onClick={() => setSelectedTab("add-users")}
              >
                Add users
              </a>
            </li>
            <li>
              <a
                href="#forms"
                className={selectedTab === "forms" ? "active" : ""}
                onClick={() => setSelectedTab("forms")}
              >
                Forms
              </a>
            </li>
            <li>
              <a
                href="#ticket"
                className={selectedTab === "ticket" ? "active" : ""}
                onClick={() => setSelectedTab("ticket")}
              >
                Blueprints and levels
              </a>
            </li>
            <li>
              <a
                href="#create-ticket"
                className={selectedTab === "create-ticket" ? "active" : ""}
                onClick={() => setSelectedTab("create-ticket")}
              >
                Ticket
              </a>
            </li>
            {/* <li>
              <a
                href="#print"
                className={selectedTab === "print" ? "active" : ""}
                onClick={() => setSelectedTab("print")}
              >
                Print
              </a>
            </li> */}
          </ul>
        </nav>
      </div>

      <div>
        {selectedTab === "details" && <EditDetails />}
        {selectedTab === "add-users" && <EditAddUsers />}
        {selectedTab === "forms" && <Forms />}
        {selectedTab === "ticket" && <BlueprintsAndLevels />}
        {selectedTab === "create-ticket" && <CreateTicket />}
        {/* {selectedTab === "print" && <PrintTicket />} */}
      </div>
    </div>
  );
};
