import React, {
  useRef,
  useEffect,
  useState,
  createContext,
  useContext,
} from "react";
import { MapContainer, useMap, ZoomControl } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/images/marker-shadow.png";

const MapContext = createContext();

export const useMapContext = () => useContext(MapContext);

export const MapProvider = ({ children }) => {
  const [mapState, setMapState] = useState({
    PinX: 0,
    PinY: 0,
    PlanImg: null,
    imageHeight: 0,
    imageWidth: 0,
  });

  const setMapValues = (values) =>
    setMapState((prev) => ({ ...prev, ...values }));

  const resetMapValues = () =>
    setMapState({
      PinX: 0,
      PinY: 0,
      PlanImg: null,
      imageHeight: 0,
      imageWidth: 0,
    });

  const contextValue = {
    ...mapState,
    setMapValues,
    resetMapValues,
  };

  return (
    <MapContext.Provider value={contextValue}>{children}</MapContext.Provider>
  );
};

const MapContent = ({
  imageUrl,
  imgHeight,
  imgWidth,
  pinY = 0,
  pinX = 0,
  draggable,
}) => {
  const map = useMap();
  const { setMapValues } = useMapContext();

  useEffect(() => {
    const initialCenter = [imgHeight / 2, imgWidth / 2];
    const initialZoom = -3;
    const bounds = [
      [0, 0],
      [imgHeight, imgWidth],
    ];

    map.eachLayer(function (layer) {
      map.removeLayer(layer);
    });

    const image = L.imageOverlay(imageUrl, bounds).addTo(map);
    map.setView(initialCenter, initialZoom);
    map.invalidateSize();

    const markerDrag = L.marker([pinX, pinY], {
      draggable: draggable,
      icon: L.icon({
        iconUrl: iconRetina,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
      }),
    }).addTo(map);

    if (draggable) {
      markerDrag.on("dragend", function (event) {
        const position = event.target.getLatLng();
        console.log("Position:", position);

        markerDrag.setLatLng(position).bindPopup(position.toString()).update();
        console.log("kolko je pin x lat", position.lat);
        console.log("kolko je pin y lng", position.lng);
        setMapValues({
          PinX: position.lat,
          PinY: position.lng,
          PlanImg: imageUrl,
          imageHeight: imgHeight,
          imageWidth: imgWidth,
        });
      });
    }

    // Cleanup function to remove the marker when the component unmounts
    return () => {
      map.removeLayer(markerDrag);
    };
  }, [map, imageUrl, imgHeight, imgWidth, pinX, pinY, draggable, setMapValues]);

  return <></>;
};

const LeafletMap = ({
  imageUrl,
  imgHeight,
  imgWidth,
  pinY,
  pinX,
  draggable,
  mapHeight = "700px",
  mapWidth = "1500px",
}) => {
  if (!imageUrl) return null;
  console.log("je l cita ovde podatke", imageUrl, pinX, pinY);
  console.log("a dimenzije", mapHeight, mapWidth);
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px 0",
      }}
    >
      <MapContainer
        id="map"
        style={{
          height: mapHeight,
          width: mapWidth,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        crs={L.CRS.Simple}
        minZoom={-5}
        zoomControl={false}
      >
        <MapContent
          imageUrl={imageUrl}
          imgHeight={imgHeight}
          imgWidth={imgWidth}
          pinX={pinX}
          pinY={pinY}
          draggable={draggable}
        />
        <ZoomControl position="topleft" />
      </MapContainer>
    </div>
  );
};

export default LeafletMap;
