import React from "react";
import "../BrandschutzdokuRichtigForm/BrandschutzdokuRichtigForm.scss";
import { useState, useEffect } from "react";
import { CiSettings } from "react-icons/ci";
import { useRef } from "react";
import { GrFormClose } from "react-icons/gr";
import { LuPanelLeftOpen } from "react-icons/lu";
import { EditBrandschutzdokuRichtigListPopup } from "../EditBrandschutzdokuRichtigListPopup/EditBrandschutzdokuRichtigListPopup";

export const BrandschutzdokuRichtigForm = (props) => {
  const [formData, setFormData] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const userToken = localStorage.getItem("userToken");
  const formId = props.formId;
  const [listItems, setListItems] = useState([]);
  const [showEditListPopup, setShowEditListPopup] = useState(false);
  const [listId, setListId] = useState("");
  const [currentFormFieldId, setCurrentFormFieldId] = useState("");
  const urlParams = new URLSearchParams(window.location.search);
  const projectID = urlParams.get("project_id");
  const [selectedListNames, setSelectedListNames] = useState({});
  const [listName, setListName] = useState("");
  const [selectValue, setSelectValue] = useState("");

  useEffect(() => {
    if (!formId || !projectID || !userToken) {
      return;
    }
    const fetchData = async () => {
      try {
        const formResponse = await fetch(
          `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/form-fields-select_w.php?form_id=${formId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Token: userToken,
            },
          }
        );

        if (!formResponse.ok) {
          throw new Error("Network response was not ok");
        }

        const formData = await formResponse.json();
        setFormData((prevData) => {
          console.log("form data", prevData);
          console.log("form data updated", formData.data);

          return formData.data;
        });

        const selectedFieldsResponse = await fetch(
          `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-form-fields-select_w.php?form_id=${formId}&project_id=${projectID}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Token: userToken,
            },
          }
        );
        console.log("udjes li ovde");

        if (!selectedFieldsResponse.ok) {
          throw new Error("Network response was not ok");
        }

        const selectedFieldsData = await selectedFieldsResponse.json();
        const selectedFields = selectedFieldsData.data;
        console.log("initial selected fields", selectedFields);

        const initialSelectedIds = selectedFields.map(
          (field) => field.form_field_id
        );
        setSelectedIds(initialSelectedIds);
        console.log("Initial selected ids:", initialSelectedIds);
        console.log("selected ids", selectedIds);

        console.log("sel fields data", selectedFieldsData.data);
        const listField = selectedFieldsData.data.find(
          (field) => field.field_type === "list"
        );
        console.log("lista vrednost", listField);
        setSelectValue(listField.list_id);
        console.log("vrednost", selectValue);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [formId, projectID, userToken]);

  console.log(" selected formId", formId);

  useEffect(() => {
    if (!userToken) {
      return;
    }
    const fetchListItems = async () => {
      try {
        const response = await fetch(
          "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/lists-select_w.php",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Token: userToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log("data", data);
        console.log("data data", data.data);
        setListItems(data.data);

        console.log("list items", listItems);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchListItems();
  }, [userToken]);

  const handleCheckboxChange = (formFieldId) => {
    console.log("formFieldId", formFieldId);

    setSelectedIds((prevSelected) => {
      if (prevSelected.includes(formFieldId)) {
        return prevSelected.filter((id) => id !== formFieldId);
      } else {
        return [...prevSelected, formFieldId];
      }
    });

    console.log("selected ids", selectedIds);
  };

  const handleApplyButtonClick = () => {
    const requestBody = {
      project_id: projectID,
      fields_array: formData.map((field) => ({
        selected: selectedIds.includes(field.form_field_id),
        form_field_id: field.form_field_id,
      })),
    };

    fetch(
      "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-form-fields-save_w.php",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Token: userToken,
        },
        body: JSON.stringify(requestBody),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("Server response after saving:", data);
        props.onClose();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const handleSelectChange = (e, formFieldId) => {
    const { value } = e.target;
    setSelectedListNames((prevSelectedListNames) => ({
      ...prevSelectedListNames,
      [formFieldId]: value,
    }));

    setListId(value);
    console.log("list id", listId);
    setCurrentFormFieldId(formFieldId);
  };

  console.log("sta vraca onClose", props.onClose);

  return (
    <div className="brandschutzdoku-richtig-form">
      <div className="info">
        <div className="form-name">Form: {props.formName}</div>
        <div className="assign">
          Please assign a list to each attribute in this form
        </div>
      </div>

      <div className="table-form">
        <table>
          <thead>
            <tr>
              <th>Mandatory field</th>
              <th>Attribute Title</th>
              <th>Type</th>
              <th>Edit Assignment</th>
            </tr>
          </thead>
          <tbody>
            {formData.map((field) => (
              <tr key={field.form_field_id}>
                <td>
                  <input
                    type="checkbox"
                    id={`checkbox-${field.form_field_id}`}
                    name={`checkbox-${field.form_field_id}`}
                    onChange={() => handleCheckboxChange(field.form_field_id)}
                    checked={selectedIds.includes(field.form_field_id)}
                  />
                </td>
                <td>{field.field_title}</td>
                <td>
                  {field.field_type === "list" ? (
                    <div className="select-field">
                      <select
                        onChange={(e) =>
                          handleSelectChange(e, field.form_field_id)
                        }
                        value={
                          selectedListNames[field.form_field_id] ||
                          selectValue ||
                          ""
                        }
                      >
                        <option value="">No list assigned</option>
                        {listItems.map((item) => (
                          <option key={item.list_id} value={item.list_id}>
                            {item.list_name}
                          </option>
                        ))}
                      </select>

                      <CiSettings onClick={setShowEditListPopup} />
                    </div>
                  ) : (
                    field.field_type
                  )}
                </td>
                <td></td>
              </tr>
            ))}
          </tbody>
        </table>
        {showEditListPopup && (
          <EditBrandschutzdokuRichtigListPopup
            onClose={() => setShowEditListPopup(false)}
            listId={listId}
            currentFormFieldId={currentFormFieldId}
          />
        )}
        <div className="buttons">
          <button onClick={handleApplyButtonClick}>Apply</button>
          <button onClick={props.onClose}>Cancel</button>
        </div>
      </div>
    </div>
  );
};
