import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

export const EditDetails = () => {
  const userToken = localStorage.getItem("userToken");
  const urlParams = new URLSearchParams(window.location.search);
  const projectID = urlParams.get("project_id");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    if (projectID) {
      fetch(
        `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-select_w.php?project_id=${projectID}`,
        {
          method: "GET",
          headers: {
            Token: userToken,
          },
        }
      )
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error("Server je vratio status: " + response.status);
          }
        })
        .then((data) => {
          if (data.response) {
            const projectData = data.data;
            document.title = `Ciric documentation - ${projectData.project_name}`;
          } else {
            console.error(data.message);
          }
        })
        .catch((error) => {
          console.error("Greška prilikom dohvatanja podataka:", error);
        });
    } else {
      document.title = `Ciric documentation`;
    }
  }, [projectID]);

  const [projectDetails, setProjectDetails] = useState({
    projectName: "",
    projectNumber: "",
    startDate: null,
    endDate: null,
    streetName: "",
    zipCode: "",
    town: "",
    state: "",
  });

  useEffect(() => {
    if (projectID) {
      fetch(
        `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-select_w.php?project_id=${projectID}`,
        {
          method: "GET",
          headers: {
            Token: userToken,
          },
        }
      )
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error("Server je vratio status: " + response.status);
          }
        })
        .then((data) => {
          if (data.response) {
            const projectData = data.data;
            setProjectDetails({
              projectName: projectData.project_name,
              projectNumber: projectData.project_code,
              startDate:
                projectData.project_start !== null
                  ? new Date(projectData.project_start)
                  : null,
              endDate:
                projectData.project_end !== null
                  ? new Date(projectData.project_end)
                  : null,
              streetName: projectData.street,
              zipCode: projectData.zip_code,
              town: projectData.city,
              state: projectData.country,
            });
          } else {
            console.error(data.message);
          }
        })
        .catch((error) => {
          console.error(
            "Došlo je do greške prilikom slanja zahteva na server:",
            error
          );
        });
    }
  }, [projectID]);

  const handleSaveDetails = async (e) => {
    if (!projectDetails.projectName) {
      return;
    }
    e.preventDefault();
    const requestBody = {
      project_id: projectID,
      project_name: projectDetails.projectName,
      project_code: projectDetails.projectNumber,
      project_start:
        projectDetails.startDate && moment(projectDetails.startDate).isValid()
          ? moment(projectDetails.startDate).format("YYYY-MM-DD")
          : null,
      project_end:
        projectDetails.endDate && moment(projectDetails.endDate).isValid()
          ? moment(projectDetails.endDate).format("YYYY-MM-DD")
          : null,
      street: projectDetails.streetName,
      zip_code: projectDetails.zipCode,
      city: projectDetails.town,
      country: projectDetails.state,
    };
    try {
      const response = await fetch(
        "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-update_w.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Token: userToken,
          },

          body: JSON.stringify(requestBody),
        }
      );
      console.log("json", JSON.stringify(requestBody));

      if (response.status === 200) {
        const data = await response.json();
        if (data.response) {
          console.log("Podaci su uspešno sačuvani.");
          setShowSuccessMessage(true);
        } else {
          console.error(data.message);
        }
      } else {
        console.error("Server je vratio status: " + response.status);
      }
    } catch (error) {
      console.error(
        "Došlo je do greške prilikom slanja zahteva na server:",
        error
      );
    }
  };

  const handleProjectNameChange = (e) => {
    setProjectDetails({ ...projectDetails, projectName: e.target.value });
  };

  const handleProjectNumberChange = (e) => {
    setProjectDetails({ ...projectDetails, projectNumber: e.target.value });
  };

  const handleStartDateChange = (date) => {
    setProjectDetails({ ...projectDetails, startDate: date });
  };

  const handleEndDateChange = (date) => {
    setProjectDetails({ ...projectDetails, endDate: date });
  };

  const handleStreetNameChange = (e) => {
    setProjectDetails({ ...projectDetails, streetName: e.target.value });
  };

  const handleZipCodeChange = (e) => {
    setProjectDetails({ ...projectDetails, zipCode: e.target.value });
  };

  const handleTownChange = (e) => {
    setProjectDetails({ ...projectDetails, town: e.target.value });
  };

  const handleStateChange = (e) => {
    setProjectDetails({ ...projectDetails, state: e.target.value });
  };

  return (
    <div className="basic-information">
      <div className="create-details">
        <div className="details">
          <div className="field">
            <label htmlFor="project-name">Name des Projekts</label>
            <input
              value={projectDetails.projectName}
              onChange={handleProjectNameChange}
              placeholder="Geben Sie einen Projektnamen ein"
              id="projectName"
              name="projectName"
              style={{ width: "100%" }}
            />
          </div>

          <div className="field">
            <label htmlFor="project-number">Projektnummer</label>
            <input
              value={projectDetails.projectNumber}
              onChange={handleProjectNumberChange}
              placeholder="Geben Sie die Projektnummer ein"
              id="projectNumber"
              name="projectNumber"
              style={{ width: "100%" }}
            />
          </div>
          <div className="date">
            <div className="start-date">
              <label htmlFor="project-start">Projektstart</label>
              <DatePicker
                className="date-picker"
                selected={
                  projectDetails.startDate !== null
                    ? projectDetails.startDate
                    : null
                }
                onChange={handleStartDateChange}
                dateFormat="dd.MM.yyyy"
                placeholderText="Startdatum"
              />
            </div>
            <div className="end-date">
              <label htmlFor="project-end">Projektende Datum wählen</label>
              <DatePicker
                className="date-picker"
                selected={
                  projectDetails.endDate !== null
                    ? projectDetails.endDate
                    : null
                }
                onChange={handleEndDateChange}
                dateFormat="dd.MM.yyyy"
                placeholderText="Endtermin"
              />
            </div>
          </div>
          <div className="field">
            <label htmlFor="street-name">Straße</label>
            <input
              value={projectDetails.streetName}
              onChange={handleStreetNameChange}
              placeholder="Straße"
              id="street-name"
              name="streetName"
              style={{ width: "100%" }}
            />
          </div>
          <div className="field">
            <label htmlFor="zip-code">PLZ</label>
            <input
              value={projectDetails.zipCode}
              onChange={handleZipCodeChange}
              placeholder="PLZ"
              id="zip-code"
              name="zipCode"
              style={{ width: "100%" }}
            />
          </div>
          <div className="field">
            <label htmlFor="town">Stadt</label>
            <input
              value={projectDetails.town}
              onChange={handleTownChange}
              placeholder="Stadt"
              id="town"
              name="town"
              style={{ width: "100%" }}
            />
          </div>
          <div className="field">
            <label htmlFor="state">Staat</label>
            <input
              value={projectDetails.state}
              onChange={handleStateChange}
              placeholder="Staat"
              id="state"
              name="state"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        {showSuccessMessage && (
          <p>Sie haben alle Änderungen erfolgreich gespeichert.</p>
        )}

        <button onClick={handleSaveDetails}>Speichern</button>
      </div>
      <img src="/images/create-project.png" alt="Project" />
    </div>
  );
};
