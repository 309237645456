import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export const UndeleteProjectPopup = (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Projekt wiederherstellen</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Sind Sie sicher, dass Sie das Projekt wiederherstellen möchten?{" "}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Schließen
        </Button>
        <Button variant="primary" onClick={props.onUndelete}>
          Änderungen speichern
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
