import React, { useState } from "react";
import "../Register/register.scss";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { RegistrationPopup } from "../RegistrationPopup/RegistrationPopup";
import { useFormik } from "formik";

export const Register = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [name, setName] = useState("");
  const [modalShow, setModalShow] = useState(false);

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const handleRegister = async (e) => {
    e.preventDefault();
    if (!name || !email || !password || !confirmPassword) {
      return;
    }

    const requestBody = {
      name,
      email,
      password,
    };

    try {
      const response = await fetch(
        "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/user-register_w.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        if (data.response) {
          console.log("Uspešno ste se registrovali.");
          setModalShow(true);
        } else {
          console.error(
            "Došlo je do greške prilikom registracije:",
            data.message
          );
        }
      } else {
        console.error(
          "Došlo je do greške prilikom registracije. Status kod:",
          response.status
        );
      }
    } catch (error) {
      console.log("Greška");
      console.error("Došlo je do greške prilikom registracije:", error);
    }
  };

  return (
    <div className="register-page">
      {/* <img src="/images/logo.png" /> */}

      <div className="auth-form-container">
        <h2>Register</h2>
        <form className="register-form" onSubmit={handleRegister}>
          <label htmlFor="name">Vollständiger Name</label>
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            name="name"
            id="name"
            placeholder="Vollständiger Name"
          />
          <label htmlFor="email">Email</label>
          <input
            value={formik.values.email}
            onChange={(e) => {
              formik.handleChange(e);
              setEmail(e.target.value);
            }}
            type="email"
            pattern="[^ @]*@[^ @]*"
            placeholder="email"
            id="email"
            name="email"
            onBlur={formik.handleBlur}
          />
          {formik.touched.email && formik.errors.email ? (
            <span>{formik.errors.email}</span>
          ) : null}{" "}
          <label htmlFor="password">Passwort</label>
          <div className="create-password">
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
              placeholder="passwort"
              id="password"
              name="password"
              style={{ width: "100%" }}
              minlength="8"
              required
            />
            <div
              className="password-toggle-btn"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </div>
          </div>
          <label htmlFor="password">Bestätige das Passwort</label>
          <div className="create-password">
            <input
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              type={showConfirmPassword ? "text" : "password"}
              placeholder="bestätige das passwort"
              id="confirmPassword"
              name="confirmPassword"
              style={{ width: "100%" }}
              minlength="8"
              required
            />
            <div
              className="password-toggle-btn"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
            >
              {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
            </div>
          </div>
          {password.length >= 8 &&
          confirmPassword.length >= 8 &&
          password !== confirmPassword ? (
            <p>Sie haben ein wiederholtes Passwort falsch eingegeben</p>
          ) : (
            ""
          )}
          <button className="register-btn" type="submit">
            Jetzt registrieren!
          </button>
        </form>
        {modalShow && (
          <RegistrationPopup
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        )}
        <button className="link-btn">
          Schon registriert? <Link to="/">Jetzt einloggen.</Link>
        </button>
      </div>
    </div>
  );
};
