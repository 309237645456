import React from "react";
import "../ViewProjectTickets/ViewProjectTickets.scss";
import { FaPlus } from "react-icons/fa";
import { useState, useEffect } from "react";
import { CreateTicketPopup } from "../CreateTicketPopup/CreateTicketPopup";
import { FiTrash2 } from "react-icons/fi";
import { DeleteTicketPopup } from "../DeleteTicketPopup/DeleteTicketPopup";
import LeafletMap from "../LeafetMap/LeafetMap";
import LeafletMapCoordinates from "../LeafletMapCoordinates/LeafletMapCoordinates";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";

export const ViewProjectTickets = () => {
  const { project_id: projectID } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [interactiveTickets, setInteractiveTickets] = useState([]);
  const [selectedTicketId, setSelectedTicketId] = useState(null);
  const [layers, setLayers] = useState([]);
  const [interactiveLayer, setInteractiveLayer] = useState(null);
  const [mapCoordinates, setMapCoordinates] = useState([]);
  const [imageData, setImageData] = useState(null);
  const [projectName, setProjectName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    // if (!projectID) {
    //   return;
    // }

    const fetchTickets = async () => {
      console.log("Ulazi li ovde?");
      try {
        const response = await fetch(
          `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/tickets-select-interactive_w.php?project_id=${projectID}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.json();

        if (data.response) {
          let ticketsData = data.data;
          console.log("Data data", ticketsData.tickets);
          setProjectName(ticketsData.project_name);

          // Provera da li postoji vrednost za interactiveLayer
          if (interactiveLayer) {
            console.log("Ulazi li ovde za interactiveLayer", interactiveLayer);
            // Filtriranje tiketa prema interactiveLayer
            const filteredTickets = ticketsData.tickets.filter(
              (ticket) => ticket.project_layer_id == interactiveLayer
            );

            console.log("Filtrirani tiketi:", filteredTickets);

            // Postavljanje filtriranih tiketa
            setInteractiveTickets(filteredTickets);

            // Postavljanje koordinata samo kada postoji interactiveLayer
            const coordinates = filteredTickets.map((ticket) => ({
              x: ticket.x,
              y: ticket.y,
            }));
            setMapCoordinates(coordinates);
          } else {
            setInteractiveTickets(ticketsData.tickets);
            setMapCoordinates([]);
          }

          console.log("Lista tiketa", ticketsData.tickets);
        }
      } catch (error) {
        console.error("Error fetching tickets:", error.message);
      }
    };

    fetchTickets();
  }, [projectID, interactiveLayer]);

  useEffect(() => {
    if (!projectID) {
      return;
    }
    const fetchProjectLayers = async () => {
      const endpoint = `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-layers-select-interactive_w.php?project_id=${projectID}`;

      try {
        const response = await fetch(endpoint, {
          method: "GET",
          headers: {},
        });

        const data = await response.json();

        if (data.response) {
          setLayers(data.data);
          console.log("sta je layers", data.data);
        } else {
          console.error("Error fetching project layers:", data.message);
        }
      } catch (error) {
        console.error("Error fetching project layers:", error);
      }
    };

    fetchProjectLayers();
  }, [projectID]);

  console.log("je l citas pinove", mapCoordinates);
  console.log("je l citas inter layer", interactiveLayer);

  useEffect(() => {
    if (!interactiveLayer) {
      return;
    }
    const fetchLayerImage = async () => {
      try {
        const response = await fetch(
          `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-layer-image-select-interactive_w.php?project_layer_id=${interactiveLayer}`,
          {
            method: "GET",
          }
        );

        if (!response.ok) {
          throw new Error("Not valid");
        }

        const data = await response.json();
        setImageData(data.data);
        console.log("image data", imageData);
      } catch (error) {
        console.error("Error fetching layer image:", error.message);
        return null;
      }
    };
    fetchLayerImage();
  }, [interactiveLayer]);

  const handleRowClick = (ticketId) => {
    console.log("tiket id", ticketId);
    setSelectedTicketId(ticketId);
    setShowModal(true);
  };

  return (
    <div className="view-project-tickets">
      <h1>{projectName}</h1>
      <div className="add-ticket-and-filter">
        <select
          id="layer"
          value={interactiveLayer}
          onChange={(e) => setInteractiveLayer(e.target.value)}
        >
          <option value="">Filter tickets by layer</option>
          {layers.map((layer) => (
            <option key={layer.project_layer_id} value={layer.project_layer_id}>
              {layer.layer_name}
            </option>
          ))}
        </select>
      </div>

      {interactiveLayer && imageData && mapCoordinates && !showModal && (
        <LeafletMapCoordinates
          imageUrl={imageData.img_url}
          imgHeight={imageData.img_height}
          imgWidth={imageData.img_width}
          pins={mapCoordinates}
          mapHeight="700px"
          mapWidth="1500px"
          enableDrag={false}
          tickets={interactiveTickets}
          handleRowClick={handleRowClick}
          user={0}
        />
      )}

      <div className="tickets-table">
        <table>
          <thead>
            <tr>
              <th>Order number</th>
              <th>ID</th>
              <th>Zuständiger</th>
              <th> Status</th>
              <th> Empfänger</th>
              <th> Titel</th>
              <th>Autor</th>
              <th>Aktualisiert am</th>
              <th>Aktualisiert von</th>
              <th>Datum</th>
              <th>Brandschutzsystem</th>
            </tr>
          </thead>
          <tbody>
            {interactiveTickets.length === 0 ? (
              <tr>
                <td colSpan="9">No projects available</td>
              </tr>
            ) : (
              interactiveTickets.map((ticket) => (
                <tr
                  key={ticket.project_id}
                  onClick={() => handleRowClick(ticket.ticket_id)}
                >
                  <td>{ticket.order_number}</td>

                  <td>{ticket.ticket_id}</td>
                  <td>{ticket.assignee_name}</td>
                  <td>{ticket.ticket_status}</td>
                  <td></td>
                  <td>{ticket.title}</td>
                  <td>{ticket.author_name}</td>
                  <td>{ticket.created_date}</td>
                  <td>
                    {ticket.updated_name !== null ? ticket.updated_name : ""}
                  </td>
                  <td>{ticket.updated_date}</td>
                  <td>""</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {showModal && (
        <CreateTicketPopup
          onHide={() => {
            setShowModal(false);
            setSelectedTicketId(null);
          }}
          selectedTicketId={selectedTicketId}
          filteredLayer={interactiveLayer}
          user={0}
          project_id={projectID}
        />
      )}
    </div>
  );
};
