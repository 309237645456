import "../src/styles/App.scss";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Login } from "./components/Login/Login";
import { Register } from "./components/Register/Register";
import { ResetPassword } from "./components/ResetPassword/ResetPassword";
import { HomePage } from "./components/HomePage/HomePage";
import { NewPassword } from "./components/NewPassword/NewPassword";
import { MapProvider } from "./components/LeafetMap/LeafetMap";
import { useEffect } from "react";
import AuthCheck from "./components/AuthCheck/AuthCheck";
import { ViewProjectTickets } from "./components/ViewProjectTickets/ViewProjectTickets";

function App() {
  const userToken = localStorage.getItem("userToken");

  console.log("je l vidi userToken", userToken);

  useEffect(() => {
    document.title = "Ciric documentation";
  }, []);

  return (
    <div className="App" style={{ overflowX: "auto", overflowY: "auto" }}>
      <MapProvider>
        <Router>
          <Routes>
            <Route element={<AuthCheck />} />
            <Route
              path="/"
              element={userToken ? <Navigate to="/homePage" /> : <Login />}
            />

            <Route path="/register" element={<Register />} />
            <Route path="/resetPassword" element={<ResetPassword />} />
            <Route path="/setNewPassword/:id" element={<NewPassword />} />
            <Route path="/homePage/*" element={<HomePage />} />
            <Route
              path="/view-project-tickets/:project_id"
              element={<ViewProjectTickets />}
            />
          </Routes>
        </Router>
      </MapProvider>
    </div>
  );
}

export default App;
