import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "../Login/login.scss";
import { Link } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export const Login = (props) => {
  const [email, setEmail] = useState(() =>
    localStorage.checkbox ? localStorage.username : ""
  );
  const [password, setPassword] = useState(() =>
    localStorage.checkbox ? localStorage.password : ""
  );
  const [showPassword, setShowPassword] = useState(false);
  const [err, setErr] = useState(false);
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(() => !!localStorage.checkbox);
  const [errorMessage, setErrorMessage] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!email || !password) {
      setErr(true);
      return;
    }

    setErr(false);

    const requestBody = {
      email,
      password,
      application: "web",
    };

    try {
      const response = await fetch(
        "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/user-login_w.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (!response.ok) {
        console.log("ulaz 1");

        if (response.status === 400 || response.status === 500) {
          console.log("ulaz 2");
          const data = await response.json();
          setErr(true);
          setErrorMessage(data.message);
          console.log("poruka", data.message);
        } else {
          throw new Error("Anmeldung fehlgeschlagen!");
        }
      } else {
        console.log("ulaz 3");

        const data = await response.json();
        if (data.response) {
          localStorage.setItem("userName", data.data.name);
          localStorage.setItem("userToken", data.data.token);
          localStorage.setItem("admin", data.data.admin);
          if (isChecked && email !== "") {
            localStorage.username = email;
            localStorage.password = password;
            localStorage.checkbox = isChecked ? "1" : "";
          }

          console.log("Uspešno ste se prijavili.");
          navigate("/homePage");
        } else {
          setErr(true);
          if (data.message) {
            setErrorMessage(data.message);
          } else {
            console.log("greska", data.message);
            // Postavljanje generičke poruke greške za prikaz
            setErrorMessage("Došlo je do greške prilikom prijave.");
          }
        }
      }
    } catch (error) {
      console.error("Došlo je do greške prilikom prijave:", error);
      setErr(true);
    }
  };

  return (
    <div className="login-page">
      {/* <img src="/images/logo.png" /> */}
      <div className="auth-form-container">
        <h2>Entweder einzuloggen oder ein Nutzerkonto anzulegen</h2>

        <form className="login-form" onSubmit={handleLogin}>
          <label htmlFor="email">Email</label>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Mail"
            id="email"
            name="email"
          />

          <label htmlFor="email">Passwort</label>
          <div className="pasword">
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type={showPassword ? "text" : "password"}
              placeholder="Passwort (email passwort)"
              id="password"
              name="password"
              style={{ width: "100%" }}
            />
            <div
              className="password-toggle-btn"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <FaEye /> : <FaEyeSlash />}
            </div>
          </div>
          <div className="wrong-password">
            {err ? <p>{errorMessage}</p> : null}{" "}
          </div>
          <div className="remember-login">
            <input
              type="checkbox"
              checked={isChecked}
              name="lsRememberMe"
              onChange={(e) => setIsChecked(e.target.checked)}
            />
            <label>Merken Sie sich Benutzername und Passwort</label>
          </div>
          <button className="login-btn" type="submit">
            Einloggen
          </button>
        </form>
        <button className="link-btn" onClick={() => navigate("/register")}>
          Kein Mitglied? <Link to="/">Jetzt registrieren.</Link>
        </button>
        <button className="link-btn" onClick={() => navigate("/resetPassword")}>
          <Link to="/resetPassword">Passwort vergessen?</Link>
        </button>
      </div>
    </div>
  );
};
