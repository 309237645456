import React from "react";
import { BsArrow90DegLeft } from "react-icons/bs";
import "../EditUser/EditUser.scss";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const EditUser = () => {
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [company, setCompany] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [position, setPosition] = useState("");
  const [city, setCity] = useState("");
  const [street, setStreet] = useState("");
  const [zipCode, setZipCode] = useState("");
  const urlParams = new URLSearchParams(window.location.search);
  const userID = urlParams.get("user_id");
  const userToken = localStorage.getItem("userToken");
  const [userDetails, setUserDetails] = useState([]);
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    if (userID) {
      fetch(
        `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/user-select_w.php?user_id=${userID}`,
        {
          method: "GET",
          headers: {
            Token: userToken,
          },
          url: {
            user_id: userID,
          },
        }
      )
        .then((response) => {
          console.log("Response status:", response.status);

          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error("Server je vratio status: " + response.status);
          }
        })
        .then((data) => {
          console.log("Data from server:", data);

          if (data.response) {
            const userDetails = data.data;
            console.log("podaci o korisniku", userDetails);
            setUserName(userDetails.name);
            setUserEmail(userDetails.email);
            setNewPassword("");
            setConfirmNewPassword("");
            setCompany(userDetails.company);
            setPhoneNumber(userDetails.phone);
            setPosition(userDetails.position);
            setCity(userDetails.city);
            setStreet(userDetails.street);
            setZipCode(userDetails.zip_code);
          } else {
            console.error("Nije moguće dohvatiti podatke o korisniku");
          }
        })
        .catch((error) => {
          console.error(
            "Došlo je do greške prilikom slanja zahteva na server:",
            error
          );
        });
    }
  }, [userID, userToken]);

  const handleSave = (e) => {
    e.preventDefault();

    const userData = {
      user_id: userID,
      name: userName,
      email: userEmail,
      phone: phoneNumber,
      company: company,
      position: position,
      city: city,
      zip_code: zipCode,
    };

    fetch(
      "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/user-update_w.php",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Token: userToken,
        },
        body: JSON.stringify(userData),
      }
    )
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          throw new Error("Server je vratio status: " + response.status);
        }
      })
      .then((data) => {
        if (data.response) {
          console.log("Podaci uspešno ažurirani");
          setShowSuccessMessage(true);
        } else {
          console.error("Greška prilikom ažuriranja podataka: ", data.message);
        }
      })
      .catch((error) => {
        console.error(
          "Došlo je do greške prilikom slanja zahteva na server:",
          error
        );
      });
  };

  return (
    <div className="edit-user-info">
      <div className="top-of-page">
        <BsArrow90DegLeft onClick={() => navigate(`/homePage/users`)} />
        <h2>Edit the user</h2>
      </div>
      <div className="bottom-of-page">
        <h3>Details about user</h3>
        <div className="field">
          <label htmlFor="name">Name</label>
          <input
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            placeholder="Name"
            id="name"
            name="name"
            style={{ width: "100%" }}
          />
        </div>
        <div className="field">
          <label htmlFor="email">Email</label>
          <input
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            placeholder="Email"
            id="email"
            name="email"
            style={{ width: "100%" }}
          />
        </div>
        <div className="field">
          <label htmlFor="newPassword">New Password</label>
          <input
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            type="password"
            placeholder="New Password"
            id="newPassword"
            name="newPassword"
            style={{ width: "100%" }}
          />
        </div>
        <div className="field">
          <label htmlFor="confirmNewPassword">Confirm New Password</label>
          <input
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            type="password"
            placeholder="Confirm New Password"
            id="confirmNewPassword"
            name="confirmNewPassword"
            style={{ width: "100%" }}
          />
        </div>
        <div className="field">
          <label htmlFor="company">Company</label>
          <input
            value={company}
            onChange={(e) => setCompany(e.target.value)}
            placeholder="Company"
            id="company"
            name="company"
            style={{ width: "100%" }}
          />
        </div>
        <div className="field">
          <label htmlFor="phoneNumber">Phone Number</label>
          <input
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Phone Number"
            id="phoneNumber"
            name="phoneNumber"
            style={{ width: "100%" }}
          />
        </div>
        <div className="field">
          <label htmlFor="position">Position</label>
          <input
            value={position}
            onChange={(e) => setPosition(e.target.value)}
            placeholder="Position"
            id="position"
            name="position"
            style={{ width: "100%" }}
          />
        </div>
        <div className="field">
          <label htmlFor="state">Street</label>
          <input
            value={street}
            onChange={(e) => setStreet(e.target.value)}
            placeholder="Street"
            id="street"
            name="street"
            style={{ width: "100%" }}
          />
        </div>
        <div className="field">
          <label htmlFor="city">City</label>
          <input
            value={city}
            onChange={(e) => setCity(e.target.value)}
            placeholder="City"
            id="city"
            name="city"
            style={{ width: "100%" }}
          />
        </div>
        <div className="field">
          <label htmlFor="state">Zip code</label>
          <input
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            placeholder="Zip code"
            id="zip_code"
            name="zip_code"
            style={{ width: "100%" }}
          />
        </div>
        {showSuccessMessage && (
          <p>Sie haben die Benutzerinformationen erfolgreich geändert.</p>
        )}

        <button onClick={handleSave}>Save</button>
      </div>
    </div>
  );
};
