import React from "react";
import { useState, useEffect } from "react";
import "../Forms/Forms.scss";
import { BsPlusCircleDotted } from "react-icons/bs";
import { GrFormClose } from "react-icons/gr";
import { LuPanelLeftOpen } from "react-icons/lu";
import { BrandschutzdokuRichtigForm } from "../BrandschutzdokuRichtigForm/BrandschutzdokuRichtigForm";
import { MangelForm } from "../MangelForm/MangelForm";
import { IoSparkles } from "react-icons/io5";

export const Forms = () => {
  const [selectedForms, setSelectedForms] = useState([]);
  const [selectedForm, setSelectedForm] = useState(null);
  const [selectedFormId, setSelectedFormId] = useState(null);
  const [error, setError] = useState(null);
  const [availableForms, setAvailableForms] = useState([]);
  const userToken = localStorage.getItem("userToken");
  const urlParams = new URLSearchParams(window.location.search);
  const projectID = urlParams.get("project_id");
  const [formDeleted, setFormDeleted] = useState(false);

  useEffect(() => {
    if (projectID) {
      fetch(
        `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-select_w.php?project_id=${projectID}`,
        {
          method: "GET",
          headers: {
            Token: userToken,
          },
        }
      )
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error("Server je vratio status: " + response.status);
          }
        })
        .then((data) => {
          if (data.response) {
            const projectData = data.data;
            document.title = `Ciric documentation - ${projectData.project_name}`;
          } else {
            console.error(data.message);
          }
        })
        .catch((error) => {
          console.error("Greška prilikom dohvatanja podataka:", error);
        });
    } else {
      document.title = `Ciric documentation`;
    }
  }, [projectID]);

  useEffect(() => {
    const fetchAvailableForms = async () => {
      try {
        const response = await fetch(
          "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/forms-select_w.php",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Token: userToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        if (data.response) {
          setAvailableForms(data.data);
        } else {
          setError(data.message);
        }
      } catch (error) {
        setError("Error fetching available forms: " + error.message);
      }
    };

    fetchAvailableForms();

    if (formDeleted) {
      fetchAvailableForms();
      setFormDeleted(false);
    }
  }, [formDeleted]);

  useEffect(() => {
    if (!userToken || !projectID) {
      return;
    }
    const fetchSelectedForms = async () => {
      try {
        const response = await fetch(
          `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-forms-select_w.php?project_id=${projectID}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Token: userToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        if (data.response) {
          setSelectedForms(data.data);
        } else {
          setError(data.message);
        }
      } catch (error) {
        setError("Error fetching selected forms: " + error.message);
      }
    };

    fetchSelectedForms();
  }, [formDeleted, userToken, projectID]);

  const addFormToRightSide = async (form) => {
    try {
      const response = await fetch(
        "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-form-add_w.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Token: userToken,
          },
          body: JSON.stringify({
            project_id: projectID,
            form_id: form.form_id,
          }),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        if (responseData.response) {
          setSelectedForms([...selectedForms, form]);
        } else {
          console.error(
            "Došlo je do greške prilikom dodavanja forme:",
            responseData.message
          );
        }
      } else {
        const errorData = await response.json();
        console.error(
          "Došlo je do greške prilikom slanja zahteva:",
          errorData.message
        );
      }
    } catch (error) {
      console.error("Došlo je do greške prilikom slanja zahteva:", error);
    }
  };

  const closeModal = async (formId) => {
    try {
      const response = await fetch(
        "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-form-remove_w.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Token: userToken,
          },
          body: JSON.stringify({
            project_id: projectID,
            form_id: formId,
          }),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        setFormDeleted(true);
        setSelectedForm(null);
        setSelectedFormId(null);
      } else {
        const errorData = await response.json();
        console.error(
          "Došlo je do greške prilikom brisanja forme:",
          errorData.message
        );
      }
    } catch (error) {
      console.error("Došlo je do greške prilikom slanja zahteva:", error);
    }
  };

  const openForm = (form) => {
    setSelectedForm(form.form_name);
    setSelectedFormId(form.form_id);
    console.log("sel form i id", form.form_name, form.form_id);
  };

  // const CloseForm = () => {
  //   setSelectedForm(null);
  //   setSelectedFormId(null);
  //   console.log("close form i id", null, null);
  // };

  return (
    <div className="forms">
      <div className="left-side-of-forms">
        <h4>Available forms</h4>
        <div className="available-forms">
          {availableForms.map((form) => (
            <div className="item" key={form.form_id}>
              <div>{form.form_name}</div>
              <BsPlusCircleDotted onClick={() => addFormToRightSide(form)} />
            </div>
          ))}
        </div>
      </div>

      <div className="right-side-of-forms">
        {selectedForms.length > 0 && (
          <div className="selected-forms">
            <h4>Selected forms</h4>
            <div
              className="forms"
              style={{
                border: selectedForms.length > 0 ? "1px solid" : "none",
              }}
            >
              {selectedForms.map((form) => (
                <div className="selected-item" key={form.form_id}>
                  <>
                    <GrFormClose onClick={() => closeModal(form.form_id)} />
                    <p>{form.form_name}</p>
                    <LuPanelLeftOpen
                      className="open-item"
                      onClick={() => openForm(form)}
                    />
                  </>
                </div>
              ))}
            </div>
          </div>
        )}
        <div className="open-form">
          {selectedForm && (
            <BrandschutzdokuRichtigForm
              formName={selectedForm}
              formId={selectedFormId}
              onClose={() => {
                setSelectedForm(null);
                setSelectedFormId(null);
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
