import React from "react";
import { FiTrash2 } from "react-icons/fi";
import "../Users/Users.scss";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

export const Users = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const userToken = localStorage.getItem("userToken");

  useEffect(() => {
    const fetchApprovedUsers = async () => {
      try {
        const response = await fetch(
          "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/users-approved_w.php",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Token: userToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Request failed");
        }

        const data = await response.json();
        setUsers(data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching approved users:", error.message);
        setLoading(false);
      }
    };

    fetchApprovedUsers();
  }, [userToken]);

  const handleDeleteUser = async (user_id) => {
    const requestBody = { user_id };

    try {
      const response = await fetch(
        "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/user-delete_w.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Token: userToken,
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        if (data.response) {
          console.log("Uspešno obrisan korisnik.");
        } else {
          console.error("Neuspešno brisanje korisnika.");
        }
      } else {
        console.error(
          `Došlo je do greške prilikom brisanja korisnika. Status kod: ${response.status}`
        );
      }
    } catch (error) {
      console.error("Došlo je do greške prilikom slanja zahteva:", error);
    }
  };

  return (
    <div className="users">
      <h3>Users</h3>
      <div className="field">
        <button onClick={() => navigate("/homePage/inviteUser")}>
          Invite user
        </button>
      </div>
      <div className="users-table">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>NAME</th>
              <th>EMAIL</th>
              <th>TYPE</th>
              <th>CREATED</th>
              <th>UPDATED</th>
              <th>PHONE</th>
              <th>COMPANY</th>
              <th>POSITION</th>
              <th>CITY</th>
              <th>PLZ</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="12">Loading...</td>
              </tr>
            ) : (
              users.map((user) => (
                <tr
                  key={user.user_id}
                  onClick={() =>
                    navigate(
                      `/homePage/editUserInformation?user_id=${user.user_id}`
                    )
                  }
                >
                  <td>{user.user_id}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  <td>{user.user_type_description}</td>
                  <td>{user.created}</td>
                  <td>{user.updated}</td>
                  <td>{user.phone}</td>
                  <td>{user.company}</td>
                  <td>{user.position}</td>
                  <td>{user.city}</td>
                  <td>{user.zip_code}</td>
                  <td>
                    <FiTrash2 onClick={() => handleDeleteUser(user.user_id)} />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};
