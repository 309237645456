import React from "react";
import "../ApproveTheRegistration/ApproveTheRegistration.scss";
import { useState, useEffect } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

export const ApproveTheRegistration = () => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [userApproved, setUserApproved] = useState(false);
  const [userDeleted, setUserDeleted] = useState(false);
  const userToken = localStorage.getItem("userToken");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/users-for-approve_w.php",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Token: userToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Server returned ${response.status}`);
        }

        const data = await response.json();

        if (data.response === true && data.data && Array.isArray(data.data)) {
          setUsers(data.data);
        } else {
          console.error("Došlo je do greške prilikom dohvatanja korisnika.");
        }
      } catch (error) {
        console.error(
          "Došlo je do greške prilikom dohvatanja podataka:",
          error
        );
      }
    };

    fetchData();

    if (userApproved) {
      fetchData();
      setUserApproved(false);
    }

    if (userDeleted) {
      fetchData();
      setUserDeleted(false);
    }
  }, [userToken, userApproved, userDeleted]);

  const handleApproveUser = async (user_id) => {
    const requestBody = { user_id };

    console.log("id", user_id);

    try {
      const response = await fetch(
        "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/user-approve_w.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Token: userToken,
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        if (data.response) {
          console.log("Uspešno odobren korisnik.");
          setUserApproved(true);
        } else {
          console.error("Neuspešno odobravanje korisnika.");
        }
      } else {
        console.error(
          `Došlo je do greške prilikom odobravanja korisnika. Status kod: ${response.status}`
        );
      }
    } catch (error) {
      console.error("Došlo je do greške prilikom slanja zahteva:", error);
    }
  };

  const handleDeleteUser = async (user_id) => {
    const requestBody = { user_id };

    try {
      const response = await fetch(
        "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/user-delete_w.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Token: userToken,
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        if (data.response) {
          console.log("Uspešno obrisan korisnik.");
          setUserDeleted(true);
        } else {
          console.error("Neuspešno brisanje korisnika.");
        }
      } else {
        console.error(
          `Došlo je do greške prilikom brisanja korisnika. Status kod: ${response.status}`
        );
      }
    } catch (error) {
      console.error("Došlo je do greške prilikom slanja zahteva:", error);
    }
  };

  return (
    <div className="approve-the-registration">
      {users.length > 0 ? (
        users.map((user) => (
          <div key={user.user_id} className="user-container">
            <div className="user-info">
              <div key={user.user_id} className="user">
                {user.name}
              </div>
              <div key={user.user_id} className="user">
                {user.email}
              </div>
            </div>
            <div className="user-actions">
              <AiOutlineCloseCircle
                onClick={() => handleDeleteUser(user.user_id)}
              />
              <AiOutlineCheckCircle
                onClick={() => handleApproveUser(user.user_id)}
              />
            </div>
          </div>
        ))
      ) : (
        <div className="no-users">
          There are currently no registration requirements.
        </div>
      )}
    </div>
  );
};
