import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../ResetPassword/resetPassword.scss";
import { ResetPasswordPopup } from "../ResetPasswordPopup/ResetPasswordPopup";

export const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [err, setErr] = useState("");
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const requestData = { email };

    try {
      const response = await fetch(
        "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/user-pass-reset-link_w.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.status === 200) {
        setErr("Šifra uspešno resetovana. Proverite svoj email.");
        setModalShow(true);
      } else if (response.status === 400) {
        const responseData = await response.json();
        setErr(responseData.message);
      } else if (response.status === 500) {
        const responseData = await response.json();
        setErr(responseData.error);
      }
    } catch (error) {
      console.log(err);
      console.error("Greška prilikom slanja zahteva:", error);
    }
  };

  return (
    <div className="reset-page">
      {/* <img src="/images/logo.png" /> */}

      <div className="auth-form-container">
        <h2>Entweder einzuloggen oder ein Nutzerkonto anzulegen</h2>

        <form className="reset-form" onSubmit={handleResetPassword}>
          <label htmlFor="email">Email</label>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            placeholder="Mail"
            id="email"
            name="email"
          />
          <button className="reset-btn" type="submit">
            Kennwort zurücksetzen
          </button>
        </form>
        {modalShow && (
          <ResetPasswordPopup
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        )}
        <button className="link-btn" onClick={() => navigate("/")}>
          Zurück zur einloggen
        </button>
      </div>
    </div>
  );
};
