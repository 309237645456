import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../CreateDetails/CreateDetails.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useParams } from "react-router-dom";
import { RequiredFieldPopup } from "../RequiredFieldPopup/RequiredFieldPopup";

export const CreateDetails = (props) => {
  const [projectName, setProjectName] = useState("");
  const [projectNumber, setProjectNumber] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [streetName, setStreetName] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [town, setTown] = useState("");
  const [state, setState] = useState("");
  const userToken = localStorage.getItem("userToken");
  const navigate = useNavigate();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const projectID = urlParams.get("project_id");
  const [showPopup, setShowPopup] = useState(false);

  const handleDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleSaveDetails = async (e) => {
    e.preventDefault();

    if (!projectName) {
      setShowPopup(true);
      return;
    }
    const requestBody = {
      project_name: projectName,
      project_code: projectNumber,
      project_start:
        startDate && moment(startDate).isValid()
          ? moment(startDate).format("YYYY-MM-DD")
          : null,
      project_end:
        endDate && moment(endDate).isValid()
          ? moment(endDate).format("YYYY-MM-DD")
          : null,
      street: streetName,
      zip_code: zipCode,
      city: town,
      country: state,
    };

    console.log("start date", startDate);

    let apiUrl;
    if (projectID) {
      apiUrl =
        "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-update_w.php";
      requestBody.project_id = projectID;
    } else {
      apiUrl =
        "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-insert_w.php";
    }
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Token: userToken,
        },

        body: JSON.stringify(requestBody),
      });
      console.log("json", JSON.stringify(requestBody));

      if (response.status === 200) {
        const data = await response.json();
        console.log("id projekta", data);

        if (data.response) {
          console.log("Podaci su uspešno sačuvani.");
          setShowSuccessMessage(true);
          navigate(`/homePage/createProject?project_id=${data.data}`);
          console.error(data.message);
          props.onDetailsSaved();
        }
      } else {
        console.error("Server je vratio status: " + response.status);
      }
    } catch (error) {
      console.error(
        "Došlo je do greške prilikom slanja zahteva na server:",
        error
      );
    }
  };

  useEffect(() => {
    if (projectID) {
      fetch(
        `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-select_w.php?project_id=${projectID}`,
        {
          method: "GET",
          headers: {
            Token: userToken,
          },
        }
      )
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error("Server je vratio status: " + response.status);
          }
        })
        .then((data) => {
          if (data.response) {
            const projectData = data.data;
            setProjectName(projectData.project_name);
            setProjectNumber(projectData.project_code);
            setStartDate(new Date(projectData.project_start));
            setEndDate(new Date(projectData.project_end));
            setStreetName(projectData.street);
            setZipCode(projectData.zip_code);
            setTown(projectData.city);
            setState(projectData.country);
          } else {
            console.error(data.message);
          }
        })
        .catch((error) => {
          console.error(
            "Došlo je do greške prilikom slanja zahteva na server:",
            error
          );
        });
    }
  }, [projectID]);

  console.log("koji je projectID", projectID);

  return (
    <div className="basic-information">
      <div className="create-details">
        <div className="details">
          <div className="field">
            <label htmlFor="project-name">Name des Projekts</label>
            <input
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              placeholder="Geben Sie einen Projektnamen ein"
              id="projectName"
              name="projectName"
              style={{ width: "100%" }}
            />
          </div>

          <div className="field">
            <label htmlFor="project-number">Projektnummer</label>
            <input
              value={projectNumber}
              onChange={(e) => setProjectNumber(e.target.value)}
              placeholder="Geben Sie die Projektnummer ein"
              id="projectNumber"
              name="projectNumber"
              style={{ width: "100%" }}
            />
          </div>
          <div className="date">
            <div className="start-date">
              <label htmlFor="project-start">Projektstart</label>
              <DatePicker
                className="date-picker"
                selected={startDate}
                onChange={handleDateChange}
                dateFormat="dd.MM.yyyy"
                placeholderText="Startdatum"
              />
            </div>
            <div className="end-date">
              <label htmlFor="project-end">Projektende Datum wählen</label>
              <DatePicker
                className="date-picker"
                selected={endDate}
                onChange={handleEndDateChange}
                dateFormat="dd.MM.yyyy"
                placeholderText="Endtermin"
              />
            </div>
          </div>
          <div className="field">
            <label htmlFor="street-name">Straße</label>
            <input
              value={streetName}
              onChange={(e) => setStreetName(e.target.value)}
              placeholder="Straße"
              id="street-name"
              name="streetName"
              style={{ width: "100%" }}
            />
          </div>
          <div className="field">
            <label htmlFor="zip-code">PLZ</label>
            <input
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
              placeholder="PLZ"
              id="zip-code"
              name="zipCode"
              style={{ width: "100%" }}
            />
          </div>
          <div className="field">
            <label htmlFor="town">Stadt</label>
            <input
              value={town}
              onChange={(e) => setTown(e.target.value)}
              placeholder="Stadt"
              id="town"
              name="town"
              style={{ width: "100%" }}
            />
          </div>
          <div className="field">
            <label htmlFor="state">Staat</label>
            <input
              value={state}
              onChange={(e) => setState(e.target.value)}
              placeholder="Staat"
              id="state"
              name="state"
              style={{ width: "100%" }}
            />
          </div>
        </div>
        {showSuccessMessage && <p>Daten zum Projekt wurden gespeichert.</p>}
        <button onClick={handleSaveDetails}>Speichern</button>
      </div>
      <img src="/images/create-project.PNG" />
      {showPopup && (
        <RequiredFieldPopup
          show={showPopup}
          onHide={() => setShowPopup(false)}
        />
      )}
    </div>
  );
};
