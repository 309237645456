import { React } from "react";
import "../Sidebar/sidebar.scss";
import { TiTicket } from "react-icons/ti";
import { ImUser } from "react-icons/im";
import { LiaPencilRulerSolid } from "react-icons/lia";
import { TbUsersPlus } from "react-icons/tb";
import { AiOutlineUnorderedList } from "react-icons/ai";
import { FiUsers } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { FcDeleteDatabase } from "react-icons/fc";
import { LiaProjectDiagramSolid } from "react-icons/lia";

export const Sidebar = (props) => {
  const navigate = useNavigate();
  const userName = localStorage.getItem("userName");
  const admin = localStorage.getItem("admin");
  const userToken = localStorage.getItem("userToken");

  const Logout = () => {
    if (userToken) {
      localStorage.removeItem("userToken");
    }
    navigate("/");
  };

  console.log("admin:", admin);

  console.log("user name:", userName);
  return (
    <div className="sidebar">
      <div className="top-of-sidebar">
        <ImUser />
        <div className="userName">{userName}</div>
        <div className="hover-class">
          <div className="logout" onClick={Logout}>
            Logout
          </div>
        </div>
      </div>
      <div className="bottom-of-sidebar">
        {/* <div
          className="item"
          // onClick={() => navigate("/homePage/createTicket")}
        >
          <TiTicket />
          <div className="">Tickets</div>
        </div> */}
        <div
          className="item"
          onClick={() => navigate("/homePage/createProject")}
        >
          <LiaPencilRulerSolid />
          <div className="">Create Project</div>
        </div>

        <div
          className="item"
          onClick={() => navigate("/homePage/listOfProjects")}
        >
          <LiaProjectDiagramSolid />
          <div className="">Projects</div>
        </div>
        <div
          className="item"
          onClick={() => navigate("/homePage/deletedProjects")}
        >
          <FcDeleteDatabase />
          <div className="">Deleted Projects</div>
        </div>
        <div className="item" onClick={() => navigate("/homePage/createLists")}>
          <AiOutlineUnorderedList />
          <div className=""> Listen </div>
        </div>
        <div className="item" onClick={() => navigate("/homePage/users")}>
          <FiUsers />
          <div className="">Users</div>
        </div>
        <div
          className="item"
          onClick={() => navigate("/homePage/approveTheRegistration")}
        >
          <TbUsersPlus />
          <div className="">Accept Users</div>
        </div>
      </div>
    </div>
  );
};
