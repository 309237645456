import React, { useState, useEffect, useCallback } from "react";
import {
  MapContainer,
  useMap,
  ZoomControl,
  Marker,
  Popup,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/images/marker-shadow.png";
import ImageViewer from "react-simple-image-viewer";
import { FaRegTrashAlt } from "react-icons/fa";
import { DeleteImagePopup } from "../DeleteImagePopup/DeleteImagePopup";

const MapContent = ({
  imageUrl,
  imgHeight,
  imgWidth,
  pins,
  tickets,
  handleRowClick,
  setIsImageDelete,
  user,
}) => {
  const userToken = localStorage.getItem("userToken");
  const [currentImageIndex, setCurrentImageIndex] = useState(null); // Dodato
  const [imagesArray, setImagesArray] = useState([]);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [showDeleteImagePopup, setShowDeleteImagePopup] = useState(false);
  console.log("je l cita pinove", pins);
  useEffect(() => {
    if (tickets.length > 0) {
      const imageUrls = tickets
        .filter((ticket) => ticket.files && ticket.files.length > 0)
        .flatMap((ticket) => ticket.files.map((file) => file.file_url));
      setImagesArray(imageUrls);
      console.log("img arr", imagesArray);
    }
  }, [tickets]);

  const openImageViewer = (index) => {
    setCurrentImageIndex(index);
    setIsViewerOpen(true);
  };

  const closeImageViewer = () => {
    setCurrentImageIndex(null);
    setIsViewerOpen(false);
  };

  console.log("cur ind", currentImageIndex);

  // const handleDeleteImage = async (index) => {
  //   console.log(" del ind", index);
  //   try {
  //     const ticket = tickets.find(
  //       (ticket) => ticket.files && ticket.files.length > 0
  //     );
  //     if (!ticket) {
  //       console.error("Ticket not found");
  //       return;
  //     }

  //     const fileToDelete = ticket.files[index];
  //     if (!fileToDelete) {
  //       console.error("File not found");
  //       return;
  //     }

  //     const ticket_file_id = fileToDelete.ticket_file_id;
  //     console.log("ticket_file_id za brisanje", ticket_file_id);

  //     const response = await fetch(
  //       `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/ticket-file-delete_w.php`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Token: userToken,
  //         },
  //         body: JSON.stringify({
  //           ticket_file_id: ticket_file_id,
  //         }),
  //       }
  //     );

  //     const data = await response.json();

  //     if (response.ok) {
  //       console.log("Image deleted successfully");
  //       console.log(data);
  //       setImagesArray((prevImagesArray) =>
  //         prevImagesArray.filter((_, idx) => idx !== index)
  //       );

  //       setCurrentImageIndex((prevIndex) => prevIndex + 1);

  //       setIsImageDelete(true);
  //     } else {
  //       console.error("Failed to delete image:", data.message);
  //     }
  //   } catch (error) {
  //     console.error("Error deleting image:", error.message);
  //   }
  // };

  useEffect(() => {
    if (isViewerOpen && currentImageIndex !== null && imagesArray.length > 0) {
      const imageUrl = imagesArray[currentImageIndex];
      if (imageUrl) {
        console.log("Prikazuje se slika:", imageUrl);
      } else {
        console.error("URL slike nije pronađen.");
      }
    } else {
      closeImageViewer();
    }
  }, [isViewerOpen, currentImageIndex, imagesArray, closeImageViewer]);

  console.log("pinovi procitani", pins);
  const map = useMap();

  useEffect(() => {
    const initialCenter = [imgHeight / 2, imgWidth / 2];
    const initialZoom = -3;
    const bounds = [
      [0, 0],
      [imgHeight, imgWidth],
    ];

    map.eachLayer((layer) => {
      if (layer !== map) map.removeLayer(layer);
    });

    L.imageOverlay(imageUrl, bounds).addTo(map);
    map.setView(initialCenter, initialZoom);
    map.invalidateSize();

    pins.forEach((pin) => {
      const marker = L.marker([pin.x, pin.y], { icon: pin.icon }).addTo(map);

      const ticket = tickets.find(
        (ticket) => ticket.x === pin.x && ticket.y === pin.y
      );

      marker.bindPopup(function () {
        const div = document.createElement("div");
        div.innerHTML = `
          <p style="font-size: 12px; color: gray; display: inline">Erstellt von: ${
            ticket ? ticket.author_name + ", " + ticket.created_date : ""
          }</p>
          <p style="font-size: 20px; font-weight: bold; ">${
            ticket.title ? ticket.title : ""
          }</p>
          <div style="display: flex;
          gap: 10px; padding-bottom: 10px;">
          ${
            ticket.files && ticket.files.length > 0
              ? ticket.files
                  .map(
                    (file, index) => `
                    <img 
                    key=${index}
                    src=${file.file_url} 
                    alt=${file.file_name || `Image ${index}`} 
                    style="width: 70px;
                    height: 70px; margin-top: 5px;"
                    id="image_${index}"  
                />
            `
                  )
                  .join("")
              : ""
          }
    </div>
        
  
          <div style="background-color: #ecf1f5; padding: 10px; border-radius: 10px;">
            <h3 style="font-size: 13px; font-weight: bold;">Bearbeiten</h3>
            <p style="font-size: 13px; font-weight: bold;">Status: ${
              ticket.ticket_status_title ? ticket.ticket_status_title : ""
            } </p>
            <p style="font-size: 13px; font-weight: bold;"> 
            ${
              ticket.assignee_user_id
                ? `Zuständiger: ${ticket.assignee_user_id}`
                : ""
            }
             </p>
          </div>
          <button id="editTicketBtn" style="border: 1px solid #ecf1f5;
              border-radius: 15px;
              background-color: #00b6aa;
              color: black;
              padding: 10px 20px;
              margin-top: 10px;
              margin-left: auto;">
  Show ticket
  </button>
  
        `;

        // ticket.files.forEach((file, index) => {
        //   console.log("Index:", index);

        //   const image = div.querySelector(`#image_${index}`);
        //   image.addEventListener("click", () => openImageViewer(index));
        // });
        div
          .querySelector("#editTicketBtn")
          .addEventListener("click", () =>
            handleRowClick(ticket.ticket_id, user)
          );
        return div;
      });
    });

    return () => {
      map.eachLayer((layer) => {
        if (layer !== map) map.removeLayer(layer);
      });
    };
  }, [
    map,
    imageUrl,
    imgHeight,
    imgWidth,
    pins,
    tickets,
    handleRowClick,
    openImageViewer,
    isViewerOpen,
    setIsImageDelete,
    user,
  ]);

  return <></>;
};

const LeafletMapCoordinates = ({
  imageUrl,
  imgHeight,
  imgWidth,
  pins,
  tickets,
  setIsImageDelete,
  mapHeight = "700px",
  mapWidth = "1400px",
  handleRowClick,
  isViewerOpen,
  user,
}) => {
  if (!imageUrl) return null;

  console.log("open", isViewerOpen);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px 0",
      }}
    >
      {!isViewerOpen && (
        <MapContainer
          id="map"
          style={{
            height: mapHeight,
            width: mapWidth,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
          crs={L.CRS.Simple}
          minZoom={-5}
          zoomControl={false}
        >
          <MapContent
            imageUrl={imageUrl}
            imgHeight={imgHeight}
            imgWidth={imgWidth}
            pins={pins.map((pin) => ({
              ...pin,
              icon: L.icon({
                iconUrl: iconRetina,
                iconSize: [25, 41],
                iconAnchor: [12, 41],
                popupAnchor: [1, -34],
              }),
            }))}
            tickets={tickets}
            handleRowClick={handleRowClick}
            setIsImageDelete={setIsImageDelete}
          />
          <ZoomControl position="topleft" />
        </MapContainer>
      )}
    </div>
  );
};

export default LeafletMapCoordinates;
