import React, { useState } from "react";
import { useFormik } from "formik";
import "../InviteUser/InviteUser.scss";

export const InviteUser = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const userToken = localStorage.getItem("userToken");

  const validate = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = "Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  const handleSendDetails = async () => {
    try {
      const response = await fetch(
        "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/user-invite_w.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Token: userToken,
          },
          body: JSON.stringify({ email }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        if (data.response) {
          setSuccess(true);
          setError(null);
        } else {
          setError("Neuspešno slanje pozivnice. Pokušajte ponovo.");
          setSuccess(false);
        }
      } else {
        console.log(data.message);
        setError(` ${data.message}`);
        setSuccess(false);
      }
    } catch (error) {
      setError("Došlo je do greške prilikom slanja pozivnice.");
      setSuccess(false);
    }
  };

  return (
    <div className="invite-user">
      <h3>Invite user to join application</h3>
      <div className="create-form">
        <h4>Details about user</h4>
        <div className="invite-user-form">
          <div className="field">
            <label htmlFor="email">Email</label>
            <input
              value={formik.values.email}
              onChange={(e) => {
                formik.handleChange(e);
                setEmail(e.target.value);
              }}
              type="email"
              pattern="[^ @]*@[^ @]*"
              placeholder="email"
              id="email"
              name="email"
              onBlur={formik.handleBlur}
            />
          </div>
        </div>
        <button onClick={handleSendDetails}>Send</button>
        {error && <p style={{ color: "red" }}>{error}</p>}
        {success && (
          <p style={{ color: "green" }}>
            Der Benutzer wurde erfolgreich zur Registrierung in der Anwendung
            eingeladen.
          </p>
        )}
      </div>
    </div>
  );
};
