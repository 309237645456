import React from "react";
import "../CreateTicketPopup/CreateTicketPopup.scss";
import { useState, useEffect, useRef, useCallback } from "react";
import { render } from "react-dom";
import ImageViewer from "react-simple-image-viewer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MapContainer, ImageOverlay, Marker } from "react-leaflet";
import moment from "moment";
import { FaPlus } from "react-icons/fa";
import Select from "react-select";
import { SetPinPopup } from "../SetPinPopup/SetPinPopup";
import { FaMapMarker } from "react-icons/fa";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import { logDOM } from "@testing-library/react";
import LeafletMap, { MapProvider } from "../LeafetMap/LeafetMap";
import { useMapContext } from "../LeafetMap/LeafetMap";
import { FillInAllFieldsPopup } from "../FillInAllFieldsPopup/FillInAllFieldsPopup";
import { IoIosSend } from "react-icons/io";
import { FaRegTrashAlt } from "react-icons/fa";
import { DeleteImagePopup } from "../DeleteImagePopup/DeleteImagePopup";
export const CreateTicketPopup = (props) => {
  const userToken = localStorage.getItem("userToken");
  const urlParams = new URLSearchParams(window.location.search);
  const projectID = urlParams.get("project_id");
  const [selectedForm, setSelectedForm] = useState("");
  const [layer, setLayer] = useState("");
  const [forms, setForms] = useState([]);
  const [formFields, setFormFields] = useState([]);
  const [layers, setLayers] = useState([]);
  const [parsedDates, setParsedDates] = useState({});
  const [layerImages, setLayerImages] = useState([]);
  const [ticketData, setTicketData] = useState(null);
  const [ticketStatuses, setTicketStatuses] = useState([]);
  const [selectedStatuses, setSelectedStatuses] = useState("");
  const [ticketDataFormName, setTicketDataFormName] = useState("");
  const [listFormFieldId, setListFormFieldId] = useState("");
  const [listOptions, setListOptions] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedReceiverOptions, setReceiverSelectedOptions] = useState();
  const [projectUsers, setProjectUsers] = useState([]);
  const [selectedFormId, setSelectedFormId] = useState("");
  const [showPinModal, setShowPinModal] = useState(false);
  const mapRef = useRef(null);
  const { PinX, PinY, PlanImg, imageHeight, imageWidth } = useMapContext();
  const { resetMapValues } = useMapContext();
  const [savedPinX, setSavedPinX] = useState(0);
  const [savedPinY, setSavedPinY] = useState(0);
  const [showFieldsPopup, setShowFieldsPopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imagesArray, setImagesArray] = useState([]);
  const imageContainerRef = useRef(null);
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [imageDataUrl, setImageDataUrl] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [comment, setComment] = useState("");
  const [journalData, setJournalData] = useState([]);
  const [commentAdd, setComentAdd] = useState(false);
  const [newImagesDetails, setNewImagesDetails] = useState([]);
  const [showDeleteImagePopup, setShowDeleteImagePopup] = useState(false);
  const [isImageDelete, setIsImageDelete] = useState(false);
  const [ticketFileId, setTicketFileId] = useState("");
  const [popupMessage, setPopupMessage] = useState("");

  useEffect(() => {
    if (!layer) {
      return;
    }

    const selectImage = async () => {
      try {
        let apiUrl = "";
        let headers = {};

        if (props.user === 0) {
          apiUrl = `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-layer-image-select-interactive_w.php?project_layer_id=${layer}`;
        } else {
          apiUrl = `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-layer-image-select_w.php?project_layer_id=${layer}`;
          headers = {
            "Content-Type": "application/json",
            Token: userToken,
          };
        }

        const response = await fetch(apiUrl, {
          method: "GET",
          headers: headers,
        });

        const data = await response.json();

        if (response.ok) {
          setImageData(data.data);
          setImageDataUrl(data.data.img_url);
        } else {
          console.error(
            "Greška prilikom dohvatanja podataka o slici:",
            data.message || "Greška prilikom dohvatanja podataka o slici"
          );
        }
      } catch (error) {
        console.error("Greška prilikom fetch-a:", error);
      }
    };

    selectImage();
  }, [layer]);

  useEffect(() => {
    console.log("je l cita u use eff props layer", props.filteredLayer);

    if (!props.filteredLayer) {
      return;
    }

    const selectImage = async () => {
      try {
        const response = await fetch(
          `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-layer-image-select_w.php?project_layer_id=${props.filteredLayer}`,
          {
            headers: {
              "Content-Type": "application/json",
              token: userToken,
            },
          }
        );

        const data = await response.json();

        if (response.ok) {
          setImageData(data.data);
          setImageDataUrl(data.data.img_url);
        } else {
          console.error(
            "Greška prilikom dohvatanja podataka o slici:",
            data.message || "Greška prilikom dohvatanja podataka o slici"
          );
        }
      } catch (error) {
        console.error("Greška prilikom fetch-a:", error);
      }
    };

    selectImage();
  }, [props.filteredLayer]);

  useEffect(() => {
    console.log("layer img data", imageData);
    console.log("layer img url", imageDataUrl);
    console.log("layer", layer);
  }, [imageData, layer]);

  const openImageViewer = useCallback((index, ticket_file_id) => {
    setCurrentImage(index);
    console.log("curr index", currentImageIndex);
    setTicketFileId(ticket_file_id);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  useEffect(() => {
    // if (!projectID || !props.project_id) {
    //   return;
    // }
    const fetchProjectLayers = async () => {
      const userToken = localStorage.getItem("userToken");

      let apiUrl = "";
      let headers = {};

      if (props.user === 0) {
        apiUrl = `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-layers-select-interactive_w.php?project_id=${props.project_id}`;
      } else {
        apiUrl = `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-layers-select_w.php?project_id=${projectID}`;
        headers = {
          "Content-Type": "application/json",
          Token: userToken,
        };
      }

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: headers,
      });

      const data = await response.json();

      if (data.response) {
        setLayers(data.data);
        console.log("nivoi", layers);
      } else {
        console.error("Error fetching project layers:", data.message);
      }
    };

    fetchProjectLayers();
  }, [projectID, props.project_id]);

  useEffect(() => {
    const fetchTicketStatuses = async () => {
      try {
        let apiUrl = "";
        let headers = {};

        if (props.user === 0) {
          apiUrl = `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/ticket-statuses-select-interactive_w`;
        } else {
          apiUrl = `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/ticket-statuses-select_w.php`;
          headers = {
            "Content-Type": "application/json",
            Token: userToken,
          };
        }

        const response = await fetch(apiUrl, {
          method: "GET",
          headers: headers,
        });

        const data = await response.json();

        if (data.response && data.data.length > 0) {
          setTicketStatuses(data.data);
          console.log("statusi tiketa", ticketStatuses);
        } else {
          console.error("Error fetching ticket statuses:", data.message);
        }
      } catch (error) {
        console.error("Error fetching ticket statuses:", error.message);
      }
    };

    fetchTicketStatuses();
  }, [userToken]);

  useEffect(() => {
    const fetchForms = async () => {
      let apiUrl = "";
      let headers = {};

      if (props.user === 0) {
        apiUrl = `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-forms-select-interactive_w.php?project_id=${props.project_id}`;
      } else {
        apiUrl = `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-forms-select_w.php?project_id=${projectID}`;
        headers = {
          "Content-Type": "application/json",
          Token: userToken,
        };
      }

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: headers,
      });

      const data = await response.json();

      if (data.response) {
        setForms(data.data);
        console.log("form data", forms);
      } else {
        console.error("Error:", data.message);
      }
    };

    fetchForms();
  }, []);

  useEffect(() => {
    if (!projectID || !selectedForm || props.selectedTicketId) {
      return;
    }
    const fetchFormFields = async () => {
      try {
        if (selectedForm) {
          const response = await fetch(
            `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-form-fields-select_w.php?project_id=${projectID}&form_id=${selectedForm}`,
            {
              method: "GET",
              headers: {
                Token: userToken,
              },
            }
          );

          const data = await response.json();

          if (data.response) {
            setFormFields(data.data);
            console.log("polja za formu", formFields);
          } else {
            console.error("Error:", data.message);
          }
        }
      } catch (error) {
        console.error("Error fetching form fields:", error);
      }
    };

    fetchFormFields();
  }, [projectID, selectedForm]);

  useEffect(() => {
    console.log("form fields", formFields);

    const found = Object.values(formFields).find(
      (element) => element.field_type === "list"
    );

    if (found) {
      setListFormFieldId(found.form_field_id);
    } else {
      setListFormFieldId("");
      console.log("Element sa field_type 'list' nije pronađen u formFields.");
    }
  }, [formFields]);

  useEffect(() => {
    console.log("form field id je", listFormFieldId);
  }, [listFormFieldId]);

  useEffect(() => {
    console.log("koji su list options", listOptions);
  }, [listOptions]);

  useEffect(() => {
    if (!projectID || !listFormFieldId) {
      return;
    }
    const fetchSelectOptions = async () => {
      try {
        const response = await fetch(
          `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-form-list-items-select_w.php?project_id=${projectID}&form_field_id=${listFormFieldId}`,
          {
            method: "GET",
            headers: {
              Token: userToken,
            },
          }
        );

        const data = await response.json();

        if (data.response) {
          setListOptions(data.data);
          console.log("list options", data.data);
        } else {
          console.error("Error:", data.message);
        }
      } catch (error) {
        console.error("Error fetching form fields:", error);
      }
    };
    fetchSelectOptions();
  }, [listFormFieldId, projectID]);
  console.log("projectID", projectID);
  console.log("listFormFieldId", listFormFieldId);

  useEffect(() => {
    // if (!projectID || props.selectedTicketId) {
    //   return;
    // }
    const fetchProjectUsers = async () => {
      try {
        let apiUrl = "";
        let headers = {};

        if (props.user === 0) {
          apiUrl = `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-users-interactive_w.php?project_id=${props.project_id}`;
        } else {
          apiUrl = `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-users_w.php?project_id=${projectID}`;
          headers = {
            "Content-Type": "application/json",
            Token: userToken,
          };
        }

        const response = await fetch(apiUrl, {
          method: "GET",
          headers: headers,
        });

        const data = await response.json();

        if (data.response) {
          setProjectUsers(data.data);
          console.log("korisnici na projektu", projectUsers);
        } else {
          console.log(data.message);
        }
      } catch (error) {
        console.log("Error fetching project users", error);
      }
    };

    fetchProjectUsers();
  }, [projectID]);

  const selectOptions = projectUsers.map((user) => ({
    value: user.user_id,
    label: user.name,
  }));

  console.log("select Options", selectOptions);

  useEffect(() => {
    console.log("x, y saved", savedPinX, savedPinY);
  }, [savedPinX, savedPinY]);
  useEffect(() => {
    const fetchTicketData = async () => {
      try {
        let apiUrl = "";
        let headers = {};

        if (props.user === 0) {
          apiUrl = `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/ticket-select-interactive_w.php?ticket_id=${props.selectedTicketId}`;
        } else {
          apiUrl = `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/ticket-select_w.php?ticket_id=${props.selectedTicketId}`;
          headers = {
            "Content-Type": "application/json",
            Token: userToken,
          };
        }

        const response = await fetch(apiUrl, {
          method: "GET",
          headers: headers,
        });

        const data = await response.json();

        if (data.response) {
          console.log("sta ide u data data za tiket", data.data);
          setSelectedForm(data.data.form_id);
          console.log(" sta je form id", data.data.form_id);
          setLayer(data.data.project_layer_id);
          console.log(
            "ako postoji projekat ima li layer",
            data.data.project_layer_id
          );

          setSavedPinX(data.data.x);
          setSavedPinY(data.data.y);

          setTicketDataFormName(data.data.form_name);
          console.log("je l vidi selected form name", data.data.form_name);

          setFormFields((prevFields) => {
            const updatedFields = { ...prevFields };

            data.data.fields.forEach((field) => {
              const fieldName = field.form_field_id;
              if (field.field_type === "date") {
                updatedFields[fieldName] = {
                  ...field,
                  value: field.value ? new Date(field.value) : null,
                };
                console.log("je l vidi update date", updatedFields[fieldName]);
              } else if (field.field_type === "status") {
                console.log("status", field.value);
                updatedFields[fieldName] = {
                  ...field,
                  value: field.value,
                };
                console.log("je l vidi status", updatedFields[fieldName]);
              } else if (field.field_type === "list") {
                updatedFields[fieldName] = {
                  ...field,
                  value: field.value
                    ? field.value.map((item) => ({
                        list_item_id: item.list_item_id,
                        item_name: item.item_name,
                      }))
                    : [],
                };
                console.log("je l vidi lista", updatedFields[fieldName].value);
              } else if (field.field_type === "pictures_videos") {
                console.log("value slika", field.value);
                updatedFields[fieldName] = {
                  ...field,
                  value: field.value.map((image) => ({
                    file_name: image.file_name,
                    file_url: image.file_url,
                    ticket_file_id: image.ticket_file_id,
                  })),
                };
                console.log("je l vidi sliku", updatedFields[fieldName]);
                setImagesArray(field.value.map((item) => item.file_url));
                console.log("sta je images arr", imagesArray);
              } else if (field.field_type === "receivers") {
                console.log("field.value receivers", field.value);
                const namesArray = field.value
                  ? field.value.map((item) => ({
                      user_id: item.user_id,
                      name: item.name,
                    }))
                  : [];
                console.log("niz za receivers koji saljem u value", namesArray);

                updatedFields[fieldName] = {
                  ...field,
                  value: namesArray,
                };

                console.log(
                  "je l vidi updatedFields za receivers",
                  updatedFields[fieldName].value
                );
              } else if (field.field_type === "assignee_user_id") {
                console.log("Vrednost assignee_user_id:", field.value);
                updatedFields[fieldName] = {
                  ...field,
                  value: field.value,
                };
                console.log(
                  "vrednost value assignee",
                  updatedFields[fieldName].value
                );
              } else if (field.field_type === "check_box") {
                console.log("Vrijednost check_box:", field.value);

                updatedFields[fieldName] = {
                  ...field,
                  value: field.value === 1 ? true : false,
                };
                console.log(
                  "Vrijednost value:",
                  updatedFields[fieldName].value
                );
              } else if (field.field_type === "integer") {
                console.log("sta je else", field.value);
                updatedFields[fieldName] = {
                  ...field,
                  value: field.value,
                };
              } else {
                console.log("sta je else", field.value);
                updatedFields[fieldName] = {
                  ...field,
                  value: field.value,
                };
              }
            });

            console.log("sta je updated fields", updatedFields);
            return updatedFields;
          });
        } else {
          console.error("Error fetching ticket data:", data.message);
        }
      } catch (error) {
        console.error("Error fetching ticket data:", error.message);
      }
    };

    fetchTicketData();

    if (isImageDelete) {
      fetchTicketData();
      setIsImageDelete(false);
    }
  }, [props.selectedTicketId, userToken, isImageDelete]);

  const handleSave = async () => {
    console.log("layer  postoji li", layer);
    console.log("kolko je edit", props.edit);

    if (!selectedForm) {
      setPopupMessage("Die Formularauswahl ist ein Pflichtfeld.");

      setShowFieldsPopup(true);
    }

    const requestPostData = {
      project_id: projectID,
      project_layer_id: layer || props.filteredLayer,
      pin_x: PinX || savedPinX,
      pin_y: PinY || savedPinY,
      form_id: selectedForm,

      fields: Object.values(formFields).map((field) => {
        console.log("Field:", field);

        if (field.field_type === "list") {
          return {
            form_field_id: field.form_field_id,
            value: field.value
              ? field.value.map((item) => ({
                  list_item_id: item.list_item_id,
                }))
              : null,
          };
        } else if (field.field_type === "date") {
          const formattedDate = field.value
            ? moment(field.value).format("YYYY-MM-DD")
            : null;
          return {
            form_field_id: field.form_field_id,
            value: formattedDate,
          };
        } else if (field.field_type === "pictures_videos") {
          return {
            form_field_id: field.form_field_id,
            value: field.value
              ? field.value.map((file) => ({
                  file_name: file.file_name,
                  file_base64: file.file_base64,
                }))
              : null,
          };
        } else if (field.field_type === "status") {
          return {
            form_field_id: field.form_field_id,
            value: field.value ? field.value : null,
          };
        } else if (field.field_type === "receivers") {
          return {
            form_field_id: field.form_field_id,
            value: field.value
              ? field.value.map((receiver) => ({
                  user_id: receiver.user_id,
                }))
              : null,
          };
        } else if (field.field_type === "assignee_user_id") {
          return {
            form_field_id: field.form_field_id,
            value: field.value ? field.value : null,
          };
        } else if (field.field_type === "check_box") {
          return {
            form_field_id: field.form_field_id,
            value: field.value ? field.value : false,
          };
        } else if (field.field_type === "integer") {
          return {
            form_field_id: field.form_field_id,
            value: field.value ? field.value : null,
          };
        } else {
          return {
            form_field_id: field.form_field_id,
            value: field.value ? field.value : null,
          };
        }
      }),
    };

    console.log("dodjes li do post", requestPostData);

    const requestUpdateData = {
      project_id: projectID,
      ticket_id: props.selectedTicketId,
      project_layer_id: layer || props.filteredLayer,
      pin_x: PinX || savedPinX,
      pin_y: PinY || savedPinY,
      form_id: selectedForm,

      fields: Object.values(formFields).map((field) => {
        console.log("Field:", field);

        if (field.field_type === "list") {
          return {
            form_field_id: field.form_field_id,
            value: field.value
              ? field.value.map((item) => ({
                  list_item_id: item.list_item_id,
                }))
              : null,
          };
        } else if (field.field_type === "date") {
          const formattedDate = field.value
            ? moment(field.value).format("YYYY-MM-DD")
            : null;
          return {
            form_field_id: field.form_field_id,
            value: formattedDate,
          };
        } else if (field.field_type === "status") {
          return {
            form_field_id: field.form_field_id,
            value: field.value ? field.value : null,
          };
        } else if (field.field_type === "receivers") {
          return {
            form_field_id: field.form_field_id,
            value: field.value
              ? field.value.map((receiver) => ({
                  user_id: receiver.user_id,
                }))
              : null,
          };
        } else if (field.field_type === "assignee_user_id") {
          return {
            form_field_id: field.form_field_id,
            value: field.value ? field.value : null,
          };
        } else if (field.field_type === "check_box") {
          return {
            form_field_id: field.form_field_id,
            value: field.value ? field.value : false,
          };
        } else if (field.field_type === "integer") {
          console.log("update integer", field.value);
          return {
            form_field_id: field.form_field_id,
            value: field.value ? field.value : null,
          };
        } else {
          return {
            form_field_id: field.form_field_id,
            value: field.value ? field.value : null,
          };
        }
      }),
    };

    const newImageDetails = newImagesDetails.map((image) => ({
      file_name: image.file_name,
      file_base64: image.file_base64,
    }));

    const requestUpdateImagesData = {
      ticket_id: props.selectedTicketId,
      files: newImageDetails || [],
    };
    console.log("kolko je edit 2", props.edit);

    let apiEndpoint;
    let responseData;
    console.log("dodjes li do ovde");
    if (props.edit === 1) {
      console.log("udje li tu");
      const apiEndpoint =
        "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/ticket-update_w.php";
      const responseData = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          Token: userToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestUpdateData),
      });

      if (!responseData.ok) {
        console.error("HTTP error:", responseData.status);
        setPopupMessage(
          "Formularauswahl, Titel und Karteneinfügung sind Pflichtfelder "
        );
        setShowFieldsPopup(true);
        return;
      }

      const responseDataJson = await responseData.json();

      if (!responseDataJson.response) {
        console.error("Error updating ticket:", responseDataJson.message);
        setPopupMessage(responseDataJson.message);
        setShowFieldsPopup(true);
        return;
      }

      console.log(
        "Ticket updated successfully. Ticket ID:",
        responseDataJson.data.ticket_id
      );

      const imagesResponse = await fetch(
        "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/ticket-files-upload_w.php",
        {
          method: "POST",
          headers: {
            Token: userToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestUpdateImagesData),
        }
      );

      if (!imagesResponse.ok) {
        console.error("Error uploading images:", imagesResponse.status);
      } else {
        const imagesResponseJson = await imagesResponse.json();
        if (imagesResponseJson.response) {
          console.log("Images uploaded successfully.");
        } else {
          console.error("Error uploading images:", imagesResponseJson.message);
        }
      }

      // Postavljanje stanja i čišćenje vrijednosti
      props.onHide();
      props.setIsTicketUpdate(true);
      resetMapValues();
    } else {
      console.log("udje li u 0");
      apiEndpoint =
        "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/ticket-create_w.php";
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers: {
          Token: userToken,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestPostData),
      });

      if (!response.ok) {
        console.error("HTTP error:", response.status);
        setPopupMessage(
          "Formularauswahl, Titel und Karteneinfügung sind Pflichtfelder "
        );
        setShowFieldsPopup(true);
        return;
      }

      const responseData = await response.json();

      if (!responseData.response) {
        console.error("Error creating ticket:", responseData.message);
        setPopupMessage(responseData.message);
        setShowFieldsPopup(true);
        return;
      }

      console.log(
        "Ticket created successfully. Ticket ID:",
        responseData.data.ticket_id
      );
      props.onHide();
      props.setIsTicketCreate(true);
      resetMapValues();
    }
  };

  const convertToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleFileChange = async (e, fieldName) => {
    try {
      const files = e.target.files;
      console.log("Selected files:", files);

      const updatedFormFields = { ...formFields };

      const images = Array.from(files);
      const base64Array = await Promise.all(
        images.map(async (file) => {
          const base64 = await convertToBase64(file);
          return {
            file_name: file.name,
            file_base64: base64,
          };
        })
      );
      console.log("Base64 images:", base64Array);

      const currentImages = updatedFormFields[fieldName].value || [];
      const newImages = base64Array.filter((newImage) => {
        return !currentImages.some(
          (currentImage) => currentImage.file_name === newImage.file_name
        );
      });

      console.log("New images:", newImages);
      setNewImagesDetails(newImages);

      const updatedImages = [...currentImages, ...newImages];

      updatedFormFields[fieldName].value = updatedImages;

      setFormFields(updatedFormFields);

      console.log("Updated formFields:", updatedFormFields);
    } catch (error) {
      console.error("Error handling files:", error);
    }
  };

  const handleDeleteImage = async (index) => {
    console.log(" del ind", index);
    console.log("ticket file id", ticketFileId);

    try {
      const response = await fetch(
        `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/ticket-file-delete_w.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Token: userToken,
          },
          body: JSON.stringify({
            ticket_file_id: ticketFileId,
          }),
        }
      );

      const data = await response.json();

      if (response.ok) {
        console.log("Image deleted successfully");
        console.log(data);

        // Ovde možete obrisati sliku iz vašeg niza slika imagesArray
        // setImagesArray((prevImagesArray) =>
        //   prevImagesArray.filter(
        //     (image) => image.ticket_file_id !== ticketFileIdToDelete
        //   )
        // );

        setCurrentImageIndex((prevIndex) => prevIndex + 1);
        setIsImageDelete(true);
      } else {
        console.error("Failed to delete image:", data.message);
      }
    } catch (error) {
      console.error("Error deleting image:", error.message);
    }
  };

  const handleFieldChange = (fieldName, value) => {
    console.log(`Promena vrednosti za polje ${fieldName}:`, value);
    setFormFields((prevFields) => ({
      ...prevFields,
      [fieldName]: {
        ...prevFields[fieldName],
        value,
      },
    }));
  };

  const handleDeleteClick = () => {
    resetMapValues();

    props.onHide();
  };

  useEffect(() => {
    if (!projectID || !props.selectedTicketId) {
      return;
    }
    const fetchTicketJournal = async (ticketId, projectId) => {
      try {
        const response = await fetch(
          `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/ticket-journal-select_w.php?ticket_id=${props.selectedTicketId}&project_id=${projectID}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Token: userToken,
            },
          }
        );

        const data = await response.json();

        if (data.response) {
          console.log("Podaci o promenama za tiket:", data.data);

          setJournalData(data.data);
          console.log("journal", journalData);
        } else {
          console.error(
            "Greška prilikom dobijanja podataka o promenama za tiket:",
            data.message
          );
          return null;
        }
      } catch (error) {
        console.error(
          "Greška prilikom dobijanja podataka o promenama za tiket:",
          error
        );
        return null;
      }
    };
    fetchTicketJournal();
    if (commentAdd) {
      fetchTicketJournal();
      setComentAdd(false);
    }
  }, [props.selectedTicketId, projectID, commentAdd]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (comment.trim() === "") {
      alert("Unesite komentar pre slanja.");
      return;
    }

    const data = {
      ticket_id: props.selectedTicketId,
      comment: comment,
    };

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Token: userToken,
      },
      body: JSON.stringify(data),
    };

    try {
      const response = await fetch(
        "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/ticket-journal-comment-insert_w.php",
        options
      );
      const responseData = await response.json();

      if (responseData.response) {
        setComment("");
        setComentAdd(true);
      } else {
        alert("Greška prilikom slanja komentara: " + responseData.message);
      }
    } catch (error) {
      console.error("Došlo je do greške:", error);
      alert(
        "Došlo je do greške prilikom slanja komentara. Molimo pokušajte ponovo."
      );
    }
  };

  const isNumberKey = (evt) => {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) return false;
    return true;
  };

  return (
    <div className="create-ticket-popup">
      <h4>
        {props.user === 1 &&
          (props.edit === 1 ? "Edit ticket" : "Create new ticket")}
        {props.user === 0 && "View ticket"}
      </h4>
      <div className="form">
        <div className="create-ticket-form">
          <div className="field">
            <label htmlFor="selectedForm">Form</label>
            <select
              id="selectedForm"
              value={selectedForm}
              onChange={(e) => setSelectedForm(e.target.value)}
              disabled={props.user === 0}
            >
              <option value=""></option>
              {forms.map((form) => (
                <option key={form.form_id} value={form.form_id}>
                  {form.form_name}
                </option>
              ))}
            </select>
          </div>

          {Object.keys(formFields).map((fieldName) => {
            const field = formFields[fieldName];

            return (
              <div key={field.form_field_id} className="field">
                {field.field_type !== "check_box" && (
                  <label htmlFor={fieldName}>{field.field_title}</label>
                )}
                {field.field_type === "list" && (
                  <Select
                    options={
                      listOptions
                        ? listOptions.map((option) => ({
                            value: option.list_item_id,
                            label: option.item_name,
                          }))
                        : []
                    }
                    placeholder="Select item"
                    value={
                      field.value && field.value.length > 0
                        ? field.value.map((item) => ({
                            value: item.list_item_id,
                            label: item.item_name,
                          }))
                        : []
                    }
                    onChange={
                      props.user !== 0 &&
                      ((selectedOptions) => {
                        const selectedValues = selectedOptions.map(
                          (option) => ({
                            list_item_id: option.value,
                            item_name: option.label,
                          })
                        );
                        handleFieldChange(fieldName, selectedValues);
                      })
                    }
                    className="react-select"
                    isSearchable={true}
                    isMulti
                    isDisabled={props.user === 0}
                  />
                )}

                {field.field_type === "status" && (
                  <div className="map-statuses">
                    <select
                      value={field.value}
                      onChange={(e) => {
                        const status = e.target.value;
                        console.log("status", status);
                        handleFieldChange(fieldName, e.target.value);
                      }}
                      disabled={props.user === 0}
                    >
                      <option value="">Select status</option>
                      {ticketStatuses.map((status) => (
                        <option
                          key={status.ticket_status_id}
                          value={status.ticket_status_id}
                        >
                          {status.ticket_status_title}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                {field.field_type === "check_box" && (
                  <div className="checkbox-field">
                    <input
                      type="checkbox"
                      checked={field.value}
                      onChange={(e) => {
                        handleFieldChange(fieldName, e.target.checked);
                      }}
                      disabled={props.user === 0}
                    />

                    <label htmlFor={fieldName}>{field.field_title}</label>
                  </div>
                )}

                {field.field_type === "assignee_user_id" && (
                  <select
                    value={field.value}
                    onChange={(e) => {
                      const assignee = e.target.value;
                      console.log("assignee", assignee);
                      handleFieldChange(fieldName, e.target.value);
                    }}
                    disabled={props.user === 0}
                  >
                    <option value="">Select assignee</option>
                    {projectUsers.map((assignee) => (
                      <option key={assignee.user_id} value={assignee.user_id}>
                        {assignee.name}
                      </option>
                    ))}
                  </select>
                )}

                {field.field_type === "receivers" && (
                  <Select
                    options={selectOptions}
                    placeholder="Add receivers"
                    value={
                      field.value
                        ? field.value.map((receiver) => ({
                            label: receiver.name,
                            value: receiver.user_id,
                          }))
                        : []
                    }
                    onChange={(selectedOptions) => {
                      console.log("Izabrane opcije:", selectedOptions);

                      handleFieldChange(
                        fieldName,
                        selectedOptions.map((option) => ({
                          user_id: option.value,
                          name: option.label,
                        }))
                      );
                    }}
                    className="react-select"
                    isSearchable={true}
                    isMulti
                    isDisabled={props.user === 0}
                  />
                )}

                {field.field_type === "pictures_videos" && (
                  <div className="add-image">
                    <input
                      type="file"
                      id={fieldName}
                      name={fieldName}
                      accept="image/*,video/*"
                      multiple
                      onChange={(e) => handleFileChange(e, fieldName)}
                      disabled={props.user === 0}
                    />
                    <div className="new-files" ref={imageContainerRef}>
                      {field.value &&
                        field.value.map((item, index) => (
                          <img
                            key={index}
                            src={item.file_base64 || item.file_url}
                            alt={item.file_name || `Image ${index}`}
                            onClick={() =>
                              openImageViewer(index, item.ticket_file_id)
                            }
                          />
                        ))}
                    </div>
                  </div>
                )}

                {field.field_type === "date" && (
                  <DatePicker
                    dateFormat="dd.MM.yyyy"
                    id={fieldName}
                    selected={field.value}
                    onChange={(date) => handleFieldChange(fieldName, date)}
                    disabled={props.user === 0}
                  />
                )}

                {field.field_type === "integer" && (
                  <input
                    value={field.value}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (!isNaN(value)) {
                        handleFieldChange(fieldName, value);
                      }
                    }}
                    type="number"
                    onKeyDown={(e) => !isNumberKey(e) && e.preventDefault()}
                    id={fieldName}
                    name={fieldName}
                    disabled={props.user === 0}
                  />
                )}

                {field.field_type !== "list" &&
                  field.field_type !== "status" &&
                  field.field_type !== "receivers" &&
                  field.field_type !== "pictures_videos" &&
                  field.field_type !== "date" &&
                  field.field_type !== "integer" &&
                  field.field_type !== "check_box" &&
                  field.field_type !== "assignee_user_id" && (
                    <input
                      value={field.value}
                      onChange={(e) =>
                        handleFieldChange(fieldName, e.target.value)
                      }
                      type={field.field_type}
                      id={fieldName}
                      name={fieldName}
                      disabled={props.user === 0}
                    />
                  )}
              </div>
            );
          })}
        </div>

        <div className="right-side">
          <div className="add-layers-and-attachments">
            <div className="field">
              <label htmlFor="layer"> Layer</label>
              <select
                id="layer"
                value={props.filteredLayer || layer}
                onChange={(e) => setLayer(e.target.value)}
                disabled={props.user === 0}
              >
                <option value=""></option>
                {layers.map((layer) => (
                  <option
                    key={layer.project_layer_id}
                    value={layer.project_layer_id}
                  >
                    {layer.layer_name}
                  </option>
                ))}
              </select>
              {(PinX && PinY && PlanImg) ||
              (savedPinX && savedPinY && imageData && !isViewerOpen) ? (
                <>
                  <div>
                    {showPinModal ? (
                      <></>
                    ) : (
                      <LeafletMap
                        imageUrl={PlanImg || imageData.img_url}
                        imgHeight={imageHeight || imageData.img_height}
                        imgWidth={imageWidth || imageData.img_width}
                        pinX={PinX || savedPinX}
                        pinY={PinY || savedPinY}
                        mapHeight="400px"
                        mapWidth="600px"
                        draggable={false}
                      />
                    )}
                  </div>
                  {props.user !== 0 && (
                    <button onClick={() => setShowPinModal(true)}>
                      <FaPlus />
                      Change plan position
                    </button>
                  )}
                </>
              ) : (
                <button onClick={() => setShowPinModal(true)}>
                  <FaPlus />
                  Set plan position
                </button>
              )}
            </div>
            {layerImages.length > 0 && (
              <div className="layer-images">
                <h5>Layer Images</h5>
                {layerImages.map((image) => (
                  <img
                    key={image.ticket_file_id}
                    src={image.file_url}
                    alt={image.file_name}
                    className="layer-image"
                  />
                ))}
              </div>
            )}
          </div>
          {props.selectedTicketId && props.user !== 0 ? (
            <div className="journal">
              <label>Journal</label>
              {journalData.map((journal, index) => (
                <div key={index} className="comment-field">
                  <div className="userName-and-time">
                    <div className="name">{journal.name}</div>
                    <div className="time">{journal.date}</div>
                  </div>
                  {journal.changes.map((change) => (
                    <div
                      className="comment"
                      key={change.ticket_journal_change_id}
                    >
                      {change.value}
                    </div>
                  ))}
                </div>
              ))}

              <div className="textarea-container">
                <textarea
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  placeholder="Type comment..."
                  isDisabled={props.user === 0}
                />
                <div
                  className="send-icon"
                  onClick={handleSubmit}
                  isDisabled={props.user === 0}
                >
                  <IoIosSend />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>

      <div className="buttons">
        {props.user !== 0 && (
          <button className="save" onClick={handleSave}>
            Save
          </button>
        )}
        <button onClick={handleDeleteClick}>Close</button>
      </div>
      {showPinModal && (
        <SetPinPopup
          layer={layer || props.filteredLayer}
          savedPinX={savedPinX}
          savedPinY={savedPinY}
          onHide={() => setShowPinModal(false)}
        />
      )}
      {showFieldsPopup && (
        <FillInAllFieldsPopup
          show={showFieldsPopup}
          onHide={() => setShowFieldsPopup(false)}
          popupMessage={popupMessage}
        />
      )}

      {isViewerOpen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1000,
          }}
        >
          <div style={{ position: "relative", height: "100%" }}>
            <ImageViewer
              src={imagesArray}
              currentIndex={currentImage}
              disableScroll={false}
              closeOnClickOutside={true}
              onClose={closeImageViewer}
            />
            <div
              style={{
                position: "absolute",
                bottom: 0,
                right: "20px",
                zIndex: 1100,
                cursor: "pointer",
                margin: "10px",
              }}
            >
              {props.user !== 0 && (
                <FaRegTrashAlt
                  style={{ width: "25px", height: "25px", color: "white" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowDeleteImagePopup(true);
                  }}
                />
              )}
            </div>
          </div>
        </div>
      )}

      {showDeleteImagePopup && (
        <DeleteImagePopup
          show={showDeleteImagePopup}
          onHide={() => setShowDeleteImagePopup(false)}
          onDelete={() => {
            handleDeleteImage(currentImageIndex);
            setShowDeleteImagePopup(false);
          }}
        />
      )}
    </div>
  );
};
