import React, { useEffect } from "react";
import { Sidebar } from "../Sidebar/Sidebar";
import { Routes, Route, useNavigate } from "react-router-dom";
import { useState } from "react";
import "../HomePage/HomePage.scss";
import { ApproveTheRegistration } from "../ApproveTheRegistration/ApproveTheRegistration";
import { CreateProject } from "../CreateProject/CreateProject";
import { Lists } from "../Lists/Lists";
import { Projects } from "../Projects/Projects";
import { EditProject } from "../EditProject/EditProject";
import { Users } from "../Users/Users";
import { InviteUser } from "../InviteUser/InviteUser";
import { EditUser } from "../EditUser/EditUser";
import { DeletedProjects } from "../DeletedProjects/DeletedProjects";
import AuthCheck from "../AuthCheck/AuthCheck";
import { CreateDetails } from "../CreateDetails/CreateDetails";
import { AddUsers } from "../AddUsers/AddUsers";
import { Forms } from "../Forms/Forms";
import { CreateTicket } from "../CreateTicket/CreateTicket";
import { BlueprintsAndLevels } from "../BlueprintsAndLevels/BlueprintsAndLevels";
import { SetPinPopup } from "../SetPinPopup/SetPinPopup";
import { useLocation } from "react-router-dom";

export const HomePage = () => {
  const [isDeleted, setIsDeleted] = useState(false);
  const navigate = useNavigate();
  const userToken = localStorage.getItem("userToken");
  const urlParams = new URLSearchParams(window.location.search);
  const projectID = urlParams.get("project_id");
  const [projectName, setProjectName] = useState("");
  useEffect(() => {
    if (projectID) {
      fetch(
        `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-select_w.php?project_id=${projectID}`,
        {
          method: "GET",
          headers: {
            Token: userToken,
          },
        }
      )
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error("Server je vratio status: " + response.status);
          }
        })
        .then((data) => {
          if (data.response) {
            const projectData = data.data;
            setProjectName(projectData.project_name);
            document.title = `Ciric documentation - ${projectData.project_name}`;
          } else {
            console.error(data.message);
          }
        })
        .catch((error) => {
          console.error("Greška prilikom dohvatanja podataka:", error);
        });
    } else {
      document.title = `Ciric documentation`;
    }
  }, [projectID]);

  useEffect(() => {
    const checkUserDeletion = async () => {
      try {
        const url = `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/user-select_w.php`;

        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Token: userToken,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        if (data.response) {
          const userDetails = data.data;

          if (userDetails.deleted !== 0) {
            console.log("Korisnik je izbrisan. Preusmeravanje na /login.");
            setIsDeleted(true);
            navigate("/");
          } else {
            console.log(
              "Korisnik nije izbrisan. Može nastaviti sa korišćenjem aplikacije."
            );
          }
        } else {
          console.error(`Greška: ${data.message}`);
        }
      } catch (error) {
        console.error(
          "Došlo je do greške prilikom obrade zahteva:",
          error.message
        );
      }
    };

    checkUserDeletion();
  }, [userToken]);

  if (isDeleted) {
    return null;
  }

  return (
    <div className="home-page">
      <AuthCheck />
      <div className="left-side ">
        <Sidebar />
      </div>
      <div className="right-side ">
        <Routes>
          <Route
            path="/approveTheRegistration"
            element={<ApproveTheRegistration />}
          />
          <Route path="/createProject" element={<CreateProject />} />
          <Route path="/createProject/details" element={<CreateDetails />} />
          <Route path="/createProject/add-users" element={<AddUsers />} />
          <Route path="/createProject/forms" element={<Forms />} />
          <Route
            path="/createProject/createTicket"
            element={<CreateTicket />}
          />
          <Route path="/createLists" element={<Lists />} />
          <Route path="/listOfProjects" element={<Projects />} />
          <Route path="/editProject" element={<EditProject />} />
          <Route path="/users" element={<Users />} />
          <Route path="/inviteUser" element={<InviteUser />} />
          <Route path="/editUserInformation" element={<EditUser />} />
          <Route path="/deletedProjects" element={<DeletedProjects />} />
          <Route
            path="/blueprintsAndLevels"
            element={<BlueprintsAndLevels />}
          />
          <Route path="/setPinOnProject" element={<SetPinPopup />} />
        </Routes>
      </div>
    </div>
  );
};
