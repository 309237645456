import React from "react";
import "../AddUsers/AddUsers.scss";
import { FiTrash2 } from "react-icons/fi";
import { useState, useEffect } from "react";
import { AddUsersPopup } from "../AddUsersPopup/AddUsersPopup";
import { ChoseRolePopup } from "../ChooseRolePopup/ChooseRolePopup";
import { DeleteUserFromProjectPopup } from "../DeleteUserFromProjectPopup/DeleteUserFromProjectPopup";

export const AddUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [showRolePopup, setShowRolePopup] = useState(false);
  const userToken = localStorage.getItem("userToken");
  const urlParams = new URLSearchParams(window.location.search);
  const projectID = urlParams.get("project_id");
  const [modalShow, setModalShow] = useState(false);
  const [selectedProjectUserId, setSelectedProjectUserId] = useState(null);
  const [roles, setRoles] = useState([]);
  const [deletingUser, setDeletingUser] = useState(false);
  const [userAdded, setUserAdded] = useState(false);

  useEffect(() => {
    if (projectID) {
      fetch(
        `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-select_w.php?project_id=${projectID}`,
        {
          method: "GET",
          headers: {
            Token: userToken,
          },
        }
      )
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else {
            throw new Error("Server je vratio status: " + response.status);
          }
        })
        .then((data) => {
          if (data.response) {
            const projectData = data.data;
            document.title = `Ciric documentation - ${projectData.project_name}`;
          } else {
            console.error(data.message);
          }
        })
        .catch((error) => {
          console.error("Greška prilikom dohvatanja podataka:", error);
        });
    } else {
      document.title = `Ciric documentation`;
    }
  }, [projectID]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await fetch(
          "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-roles_w.php",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Token: userToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Request failed");
        }

        const data = await response.json();
        setRoles(data.data);
        console.log("je l vidis u add user role", roles);
      } catch (error) {
        console.error("Error fetching roles:", error.message);
      }
    };

    fetchRoles();
  }, []);

  const handleRoleChange = async (
    newRole,
    project_id,
    user_id,
    project_role_id
  ) => {
    const userId = user_id;
    console.log("user id", userId);

    const newRoleObject = roles.find(
      (roleObject) => roleObject.role === newRole
    );

    console.log("pronadjen objekat:", newRoleObject);
    try {
      const response = await fetch(
        `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-user-edit_w.php`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Token: userToken,
          },
          body: JSON.stringify({
            project_id,
            user_id,
            project_role_id: newRoleObject.role_id,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Request failed");
      }

      const responseData = await response.json();

      if (responseData.response) {
        setUsers((prevUsers) =>
          prevUsers.map((u) =>
            u.user_id === user_id ? { ...u, role: newRole } : u
          )
        );
        console.log(`Role updated successfully for user ${user_id}`);
      } else {
        console.error("Error updating user role:", responseData.message);
      }
    } catch (error) {
      console.error("Error updating user role:", error.message);
    }
  };

  const handleShowPopup = () => {
    setShowPopup(true);
  };

  const handleHidePopup = () => {
    setShowPopup(false);
  };

  const handleShowRolePopup = () => {
    handleHidePopup();
    setShowRolePopup(true);
  };

  const handleHideRolePopup = () => {
    setShowRolePopup(false);
  };

  useEffect(() => {
    console.log("Updated users:", users);
  }, [users]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(
          `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-users_w.php?project_id=${projectID}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Token: userToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Request failed");
        }

        const data = await response.json();
        setUsers(data.data);
        console.log("users", users);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error.message);
        setLoading(false);
      }
    };

    fetchUsers();

    if (userAdded) {
      fetchUsers();
      setUserAdded(false);
    }
  }, [projectID, userToken, userAdded]);

  useEffect(() => {
    console.log("Roles:", roles);
  }, [roles]);

  useEffect(() => {
    console.log("Users:", users);
  }, [users]);

  const handleDeleteUser = async (project_id, user_id) => {
    const requestBody = { project_id, user_id };

    try {
      const response = await fetch(
        "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-user-delete_w.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Token: userToken,
          },
          body: JSON.stringify(requestBody),
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        if (data.response) {
          console.log("Uspešno obrisan korisnik.");
          setUserAdded(true);
        } else {
          console.error("Neuspešno brisanje korisnika.");
        }
      } else {
        console.error(
          `Došlo je do greške prilikom brisanja korisnika. Status kod: ${response.status}`
        );
      }
    } catch (error) {
      console.error("Došlo je do greške prilikom slanja zahteva:", error);
    }
  };

  return (
    <div className="add-users-on-project">
      {users.length > 0 ? (
        <div className="add-users">
          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>NAME</th>
                <th>UNTERNEHMEN</th>
                <th>TYP</th>
                <th>EMAIL</th>
                <th>ROLLE</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.user_id}>
                  <td>{user.user_id}</td>
                  <td>{user.name}</td>
                  <td>{user.company}</td>
                  <td>{user.type}</td>
                  <td>{user.email}</td>
                  <td>
                    <select
                      value={
                        user.role &&
                        roles.some((role) => role.role === user.role)
                          ? user.role
                          : ""
                      }
                      onChange={(e) =>
                        handleRoleChange(
                          e.target.value,
                          user.project_id,
                          user.user_id
                        )
                      }
                    >
                      {roles.map((role) => (
                        <option key={role.role_id} value={role.role}>
                          {role.role}
                        </option>
                      ))}
                    </select>
                  </td>

                  <td>
                    <FiTrash2
                      onClick={() => {
                        setSelectedProjectUserId({
                          project_id: user.project_id,
                          user_id: user.user_id,
                        });
                        setModalShow(true);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No users found.</p>
      )}
      <button value={showPopup} className="new-user" onClick={handleShowPopup}>
        Add User
      </button>
      {showPopup && (
        <AddUsersPopup
          onClose={handleHidePopup}
          handleShowRolePopup={handleShowRolePopup}
        />
      )}
      {showRolePopup && (
        <ChoseRolePopup
          onClose={handleHideRolePopup}
          setUserAdded={setUserAdded}
        />
      )}
      {modalShow && (
        <DeleteUserFromProjectPopup
          show={modalShow}
          onHide={() => setModalShow(false)}
          onDelete={() => {
            handleDeleteUser(
              selectedProjectUserId.project_id,
              selectedProjectUserId.user_id
            );
            setModalShow(false);
          }}
        />
      )}
    </div>
  );
};
