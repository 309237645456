import React from "react";
import { useState, useEffect } from "react";
import { IoClose } from "react-icons/io5";
import "../EditListPopup/EditListPopup.scss";
import { IoIosClose } from "react-icons/io";
import { FaPlus } from "react-icons/fa";
import { EditListItemNamePopup } from "../EditListItemNamePopup/EditListItemNamePopup";

export const EditListPopup = (props) => {
  const [listItems, setListItems] = useState([]);
  const userToken = localStorage.getItem("userToken");
  const [listName, setListName] = useState(props.listItemName || "");
  const [listItem, setListItem] = useState("");
  const [isListItemAdd, setIsListItemAdd] = useState(false);
  const [isListItemDelete, setIsListItemDelete] = useState(false);
  const [showEditItemName, setShowEditItemName] = useState(false);
  const [listItemName, setListItemName] = useState("");
  const [listItemId, setListItemId] = useState("");
  const [isListItemNameChanged, setIsListItemNameChanged] = useState(false);

  useEffect(() => {
    const fetchListData = async () => {
      try {
        const url = `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/list-items-select_w.php?list_id=${props.listItemId}`;
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Token: userToken,
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        if (data.response) {
          setListItems(data.data);
          console.log("list data", data.data);
        } else {
          console.error("Error:", data.message);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchListData();

    console.log("add", isListItemAdd);

    if (isListItemAdd) {
      fetchListData();
      setIsListItemAdd(false);
    }

    if (isListItemDelete) {
      fetchListData();
      setIsListItemDelete(false);
    }

    if (isListItemNameChanged) {
      fetchListData();
      setIsListItemNameChanged(false);
    }
  }, [
    userToken,
    props.listItemId,
    isListItemAdd,
    isListItemDelete,
    isListItemNameChanged,
  ]);

  const addItemToList = async () => {
    const requestBody = {
      list_id: props.listItemId,
      item_name: listItem,
    };

    try {
      const response = await fetch(
        "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/list-item-insert_w.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Token: userToken,
          },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();

      if (response.ok) {
        if (data.response) {
          console.log("Nova stavka dodata, ID:", data.data);
          setListItem("");
          setIsListItemAdd(true);
          console.log("add 1", isListItemAdd);
        } else {
          console.error("Greška prilikom dodavanja stavke:", data.message);
        }
      } else {
        console.error("Server error:", data.message);
      }
    } catch (error) {
      console.error("Greška prilikom slanja zahteva:", error);
    }
  };

  const deleteItemFromList = async (list_item_id) => {
    const requestBody = {
      list_item_id: list_item_id,
    };

    try {
      const response = await fetch(
        "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/list-item-delete_w.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Token: userToken,
          },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();

      if (response.ok) {
        if (data.response) {
          setIsListItemDelete(true);
          console.log("add 1", isListItemAdd);
        } else {
          console.error("Greška prilikom dodavanja stavke:", data.message);
        }
      } else {
        console.error("Server error:", data.message);
      }
    } catch (error) {
      console.error("Greška prilikom slanja zahteva:", error);
    }
  };

  const updateList = async () => {
    try {
      const url =
        "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/list-update_w.php";

      const requestData = {
        list_id: props.listItemId,
        list_name: listName,
      };

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Token: userToken,
        },
        body: JSON.stringify(requestData),
      });

      const responseData = await response.json();

      if (response.ok) {
        if (responseData.response) {
          console.log("Naziv liste je uspešno izmenjen.");
        } else {
          console.error("Greška:", responseData.message);
        }
        props.setIsListNameUpdated(true);
        props.onClose();
      } else {
        console.error("Došlo je do greške:", response.status);
      }
    } catch (error) {
      console.error("Došlo je do greške:", error);
    }
  };

  return (
    <div className="edit-list-popup">
      <div className="top-of-page">
        <h3>Neue Liste</h3>
        <IoClose onClick={props.onClose} />
      </div>
      <div className="middle-of-page">
        <div className="list-name">
          <div>Listen Name</div>
          <input
            value={listName}
            onChange={(e) => setListName(e.target.value)}
          />
        </div>
        <div className="add-item">
          <input
            value={listItem}
            onChange={(e) => setListItem(e.target.value)}
          />
          <FaPlus onClick={addItemToList} />
        </div>
        {listItems.length > 0 && (
          <div className="list-items">
            {listItems.map((item) => (
              <div
                className="item"
                onClick={() => {
                  setShowEditItemName(true);
                  setListItemName(item.item_name);
                  setListItemId(item.list_item_id);
                }}
              >
                <div key={item.list_item_id}>{item.item_name}</div>
                <IoIosClose
                  onClick={() => deleteItemFromList(item.list_item_id)}
                />
              </div>
            ))}
          </div>
        )}
      </div>
      <button onClick={updateList}>Save</button>
      {showEditItemName && (
        <EditListItemNamePopup
          setIsListItemNameChanged={setIsListItemNameChanged}
          listItemName={listItemName}
          listItemId={listItemId}
          onClose={() => setShowEditItemName(false)}
        />
      )}
    </div>
  );
};
