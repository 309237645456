import React from "react";
import "../DeletedProjects/DeletedProjects.scss";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MdAssignmentReturn } from "react-icons/md";
import { UndeleteProjectPopup } from "../UndeleteProjectPopup/UndeleteProjectPopup";
import moment from "moment";

export const DeletedProjects = () => {
  const [deletedProjects, setDeletedProjects] = useState([]);
  const navigate = useNavigate();
  const userToken = localStorage.getItem("userToken");
  const [showUndeleteProjectPopup, setShowUndeleteProjectPopup] =
    useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [IsPojectUndeleted, setIsProjectUndeleted] = useState(false);

  const fetchDeletedProjects = async () => {
    try {
      const response = await fetch(
        "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/projects-select-deleted_w.php",
        {
          method: "GET",
          headers: {
            Token: userToken,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        if (data.response) {
          setDeletedProjects(data.data);
        } else {
          console.error(data.message);
        }
      } else {
        throw new Error("Server je vratio status: " + response.status);
      }
    } catch (error) {
      console.error(
        "Došlo je do greške prilikom slanja zahteva na server:",
        error
      );
    }
  };

  useEffect(() => {
    fetchDeletedProjects();
  }, [userToken, IsPojectUndeleted]);

  const handleUndeleteProject = async (project_id) => {
    try {
      const response = await fetch(
        "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-undelete_w.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Token: userToken,
          },
          body: JSON.stringify({ project_id }),
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        if (data.response) {
          console.log("Uspešno undeletovan projekat.");
        } else {
          console.error("Neuspešno undeletovanje projekta.");
        }
      } else {
        console.error(
          `Došlo je do greške prilikom undeletovanja projekta. Status kod: ${response.status}`
        );
      }
    } catch (error) {
      console.error("Došlo je do greške prilikom slanja zahteva:", error);
    }

    fetchDeletedProjects();
  };

  return (
    <div className="deleted-projects">
      <h3>Deleted projects</h3>
      <div className="table-form">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>NAME</th>
              <th> PROJEKTNUMMER</th>
              <th> PROJEKTSTART</th>
              <th> PROJEKTENDE</th>
              <th>PLZ</th>
              <th>STADT</th>
              <th>STRAßE</th>
              <th>STAAT</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {deletedProjects.length === 0 ? (
              <tr>
                <td colSpan="9">No deleted projects available</td>
              </tr>
            ) : (
              deletedProjects.map((project) => (
                <tr
                  key={project.project_id}
                  onClick={(event) => {
                    event.stopPropagation(); // Zaustavi propagaciju događaja
                    navigate(
                      `/homePage/editProject?project_id=${project.project_id}`,
                      {
                        state: {
                          delete: 1,
                        },
                      }
                    );
                  }}
                >
                  <td>{project.project_id}</td>
                  <td>{project.project_name}</td>
                  <td>{project.project_code}</td>
                  <td>{project.project_start}</td>
                  <td>{project.project_end}</td>
                  <td>{project.zip_code}</td>
                  <td>{project.city}</td>
                  <td>{project.street}</td>
                  <td>{project.country}</td>
                  <td>
                    {" "}
                    <MdAssignmentReturn
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowUndeleteProjectPopup(true);
                        setSelectedProjectId(project.project_id);
                      }}
                    />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        {showUndeleteProjectPopup && (
          <UndeleteProjectPopup
            show={showUndeleteProjectPopup}
            onHide={() => setShowUndeleteProjectPopup(false)}
            onUndelete={() => {
              handleUndeleteProject(selectedProjectId);
              setShowUndeleteProjectPopup(false);
              setIsProjectUndeleted(true);
            }}
          />
        )}
      </div>
    </div>
  );
};
