import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../NewPassword/NewPassword.scss";

export const NewPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [err, setErr] = useState("");
  const navigate = useNavigate();
  let { id } = useParams();

  useEffect(() => {
    if (id === "0" || id === undefined) {
      return null;
    }
    console.log("id je", id);
  }, [id]);

  const handleSetNewPassword = async (e) => {
    e.preventDefault();
    const requestData = {
      reset_guid: id,
      newPassword: newPassword,
    };

    try {
      const response = await fetch(
        "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/user-set-pass_w.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.status === 200) {
        setErr("Uspesno ste postavili novu sifru");
      } else if (response.status === 400) {
        const responseData = await response.json();
        setErr(responseData.message);
      } else if (response.status === 500) {
        const responseData = await response.json();
        setErr(responseData.error);
      }
    } catch (error) {
      console.log(err);
      console.error("Greška prilikom slanja zahteva:", error);
    }
  };

  return (
    <div className="set-new-password">
      {/* <img src="/images/logo.png" /> */}

      <div className="auth-form-container">
        <h2>Legen Sie ein neues Passwort fest</h2>

        <form className="set-new-password-form" onSubmit={handleSetNewPassword}>
          <label htmlFor="newPassword">Neues Passwort</label>
          <input
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            type={"newPassword"}
            placeholder="newPassword"
            id="newPassword"
            name="newPassword"
            style={{ width: "100%" }}
          />
          <label htmlFor="newPassword">Bestätige neues Passwort</label>
          <input
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
            type={"confirmNewPassword"}
            placeholder="confirmNewPassword"
            id="confirmNewPassword"
            name="confirmNewPassword"
            style={{ width: "100%" }}
          />
          <button className="reset-btn" type="submit">
            Bestätigen{" "}
          </button>
        </form>
        <button className="link-btn" onClick={() => navigate("/")}>
          Zurück zur einloggen
        </button>
      </div>
    </div>
  );
};
