import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

export const RequiredFieldPopup = (props) => {
  return (
    <Modal show={props.show} onHide={props.onHide} animation={false}>
      <Modal.Header closeButton>
        <Modal.Title>Pflichtfeld für Projekt</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Um ein Projekt zu erstellen, müssen Sie zunächst den Namen des Projekts
        eingeben. Der Name des Projekts ist ein Pflichtfeld.
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={props.onHide}>
          Schließen
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
