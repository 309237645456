import React from "react";
import "../AddLevelPopup/AddLevelPopup.scss";
import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { TiPlus } from "react-icons/ti";

export const AddLevelPopup = (props) => {
  const [levelName, setLevelName] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [options, setOptions] = useState([]);
  const userToken = localStorage.getItem("userToken");
  const [selectedLayerData, setSelectedLayerData] = useState(
    props.selectedLayerData
  );
  const urlParams = new URLSearchParams(window.location.search);
  const projectID = urlParams.get("project_id");

  useEffect(() => {
    setLevelName(props.selectedLayerData?.layer_name || "");
    setSelectedLayerData(props.selectedLayerData);
  }, [props.selectedLayerData]);

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await fetch(
          `https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-layers-select_w.php?project_id=${projectID}`,
          {
            method: "GET",
            headers: {
              Token: userToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Not valid");
        }

        const data = await response.json();
        setOptions(data.data);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    };

    fetchOptions();
  }, []);

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSave = async () => {
    try {
      let apiUrl = "";
      let requestBody = {
        project_id: projectID,
        layer_name: levelName,
        parent_layer_id:
          selectedOption || selectedLayerData?.parent_layer_id || null,
        order: null,
      };
      console.log("selectedOption:", selectedOption);
      if (props.edit === 0) {
        apiUrl =
          "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-layer-insert_w.php";
      } else if (props.edit === 1) {
        apiUrl =
          "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-layer-update_w.php";
        requestBody.project_layer_id = props.selectedLayerData.project_layer_id;
      }

      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Token: userToken,
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error("Not valid");
      }

      const data = await response.json();
      console.log("Successfully added/updated layer with ID:", data.data);

      props.onHide();
      props.onLayerAdded();
      setSelectedLayerData(null);
    } catch (error) {
      console.error("Error saving layer:", error.message);
    }
  };

  return (
    <div className="add-level-popup">
      {/* <div className="add-plan">
        <div className="dashed-border">
          <TiPlus />
        </div>
      </div> */}
      <div className="level-field">
        <input
          value={levelName || selectedLayerData?.layer_name || ""}
          onChange={(e) => setLevelName(e.target.value)}
          type="text"
          placeholder="Level name"
          id="levelName"
          name="levelName"
        />
        <select
          id="customSelect"
          className="form-select"
          value={selectedOption || selectedLayerData?.parent_layer_id || ""}
          onChange={handleChange}
        >
          <option value="">Select an option</option>
          {options.map((option) => (
            <option
              key={option.project_layer_id}
              value={option.project_layer_id}
            >
              {option.layer_name}
            </option>
          ))}
        </select>
      </div>
      <div className="popup-buttons">
        <button onClick={handleSave}>Save</button>
        <button onClick={props.onHide}>Quit</button>
      </div>
    </div>
  );
};
