import React from "react";
import "../Projects/Projects.scss";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FiTrash2 } from "react-icons/fi";
import { DeleteProjectPopup } from "../DeleteProjectPopup/DeleteProjectPopup";
import moment from "moment";

export const Projects = () => {
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();
  const userToken = localStorage.getItem("userToken");
  const [showDeleteProjectPopup, setShowDeleteProjectPopup] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [IsPojectDeleted, setIsProjectDeleted] = useState(false);

  const fetchProjects = async () => {
    try {
      const response = await fetch(
        "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/projects-select_w.php",
        {
          method: "GET",
          headers: {
            Token: userToken,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        if (data.response) {
          setProjects(data.data);
        } else {
          console.error(data.message);
        }
      } else {
        throw new Error("Server je vratio status: " + response.status);
      }
    } catch (error) {
      console.error(
        "Došlo je do greške prilikom slanja zahteva na server:",
        error
      );
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [userToken, IsPojectDeleted]);

  const handleDeleteProject = async (project_id) => {
    try {
      console.log("Deleting project with ID:", project_id);

      const response = await fetch(
        "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/project-delete_w.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Token: userToken,
          },
          body: JSON.stringify({ project_id }),
        }
      );

      console.log("Server response:", response);
      const responseText = await response.text();
      console.log("Server response text:", responseText);

      if (response.status === 200) {
        const data = await response.json();
        console.log("Response data:", data);

        if (data.response) {
          console.log("Uspešno obrisan projekat.");
        } else {
          console.error("Neuspešno brisanje projekta.");
        }
      } else {
        console.error(
          `Došlo je do greške prilikom brisanja projekta. Status kod: ${response.status}`
        );
      }
    } catch (error) {
      console.error("Došlo je do greške prilikom slanja zahteva:", error);
    }
    fetchProjects();
  };
  console.log("selectedProjectId:", selectedProjectId);

  return (
    <div className="projects">
      <h3>List of projects</h3>
      <div className="table-form">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>NAME</th>
              <th> PROJEKTNUMMER</th>
              <th> PROJEKTSTART</th>
              <th> PROJEKTENDE</th>
              <th>PLZ</th>
              <th>STADT</th>
              <th>STRAßE</th>
              <th>STAAT</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {projects.length === 0 ? (
              <tr>
                <td colSpan="9">No projects available</td>
              </tr>
            ) : (
              projects.map((project) => (
                <tr
                  key={project.project_id}
                  onClick={() =>
                    navigate(
                      `/homePage/editProject?project_id=${project.project_id}`,
                      {
                        state: {
                          delete: 0,
                          projectName: project.project_name,
                        },
                      }
                    )
                  }
                >
                  <td>{project.project_id}</td>
                  <td>{project.project_name}</td>
                  <td>{project.project_code}</td>
                  <td>{project.project_start}</td>
                  <td>{project.project_end}</td>
                  <td>{project.zip_code}</td>
                  <td>{project.city}</td>
                  <td>{project.street}</td>
                  <td>{project.country}</td>
                  <td>
                    <FiTrash2
                      onClick={(e) => {
                        e.stopPropagation();
                        setShowDeleteProjectPopup(true);
                        setSelectedProjectId(project.project_id);
                      }}
                    />
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        {showDeleteProjectPopup && (
          <DeleteProjectPopup
            show={showDeleteProjectPopup}
            onHide={() => setShowDeleteProjectPopup(false)}
            onDelete={() => {
              handleDeleteProject(selectedProjectId);
              setShowDeleteProjectPopup(false);
              setIsProjectDeleted(true);
            }}
          />
        )}
      </div>
    </div>
  );
};
