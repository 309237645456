import React from "react";
import { IoCloseOutline } from "react-icons/io5";
import "../AddUsersPopup/AddUsersPopup.scss";
import { useState, useEffect, useRef } from "react";
import { ChoseRolePopup } from "../ChooseRolePopup/ChooseRolePopup";

export const AddUsersPopup = (props) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUserID, setSelectedUserID] = useState(null);
  const userToken = localStorage.getItem("userToken");
  const urlParams = new URLSearchParams(window.location.search);
  const projectID = urlParams.get("project_id");
  const [selectedUsers, setSelectedUsers] = useState([]);
  const checkboxesRef = useRef([]);

  const handleCheckboxChange = (userId) => {
    const updatedSelectedUsers = [...selectedUsers];
    const index = updatedSelectedUsers.indexOf(userId);
    if (index === -1) {
      updatedSelectedUsers.push(userId);
    } else {
      updatedSelectedUsers.splice(index, 1);
    }
    setSelectedUsers(updatedSelectedUsers);
  };

  const handleNextClick = () => {
    if (selectedUsers.length > 0) {
      sessionStorage.setItem("selectedUsers", JSON.stringify(selectedUsers));
      props.handleShowRolePopup();
    } else {
      alert("Please select at least one user.");
    }
  };

  useEffect(() => {
    const fetchApprovedUsers = async () => {
      try {
        const response = await fetch(
          "https://ciricgmbh-dokumentation.de/ciric-doku-api/a/cd/w/users-approved_w.php",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Token: userToken,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Request failed");
        }

        const data = await response.json();
        setUsers(data.data);
        setLoading(false);
        console.log("users", data.data);
      } catch (error) {
        console.error("Error fetching approved users:", error.message);
        setLoading(false);
      }
    };

    fetchApprovedUsers();
  }, [userToken]);

  return (
    <div className="add-users-popup">
      <IoCloseOutline className="close-icon" onClick={props.onClose} />
      <input className="search" type="text" placeholder="Search user" />
      <div className="table">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>ID</th>
              <th>NAME</th>
              <th>COMPANY</th>
              <th>TYPE</th>
              <th>GROUPS</th>
              <th>EMAIL</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="7">Loading...</td>
              </tr>
            ) : (
              users.map((user) => (
                <tr key={user.user_id}>
                  <td>
                    <input
                      type="checkbox"
                      id={`checkbox-${user.user_id}`}
                      value={user.user_id}
                      checked={selectedUsers.includes(user.user_id)}
                      onChange={() => handleCheckboxChange(user.user_id)}
                    />
                  </td>
                  <td>{user.user_id}</td>
                  <td>{user.name}</td>
                  <td>{user.company}</td>
                  <td>{user.type}</td>
                  <td>{user.groups}</td>
                  <td>{user.email}</td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      <div className="popup-buttons">
        <button onClick={handleNextClick}>Next</button>
        <button onClick={props.onClose}>Close</button>
      </div>
    </div>
  );
};
